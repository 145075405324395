/* for desktop-and-tablet
----------------------------------------------*/

body {
  min-width: 1141px;
}

.content-wrapper {
  max-width: $max-width;
  @media (min-width: 1141px) {
    margin: 0 auto;
  }
  @media (max-width: 1140px) {
    margin: 0 2rem;
  }
}

@media (min-width: 1141px) {
  #open-house-and-events
  , #recommended-past-works
  , #recommended-articles
  {
    .slider {
      margin: 0 auto;
      max-width: $max-width;
    }
  }
}

@media (max-width: 1140px) {
  #site-header-inner
  , #site-footer-inner
  , #page-home #news
  , #global-menu-inner
  , #site-bread-crumbs
  , #page-header h1
  {
    padding-left: 2rem;
    padding-right: 2rem;
    max-width: 100%;
  }
  #open-house-and-events
  , #recommended-past-works
  , #recommended-articles
  {
    .slider {
      margin: 0 2rem;
      width: calc(100% - 4rem);
    }
  }
  .slick-prev {
    left: -1rem;
  }
  .slick-next {
    right: -1rem;
  }
}


/* with sidebar
----------------------------------------------*/

*[id^='page-favorite'] #favorite,
#page-news #news,
#open-house-and-events-details,
#open-house-and-events-search,
#companies-search,
#past-works-search {
  @extend .content-wrapper;
}


/* error pages
----------------------------------------------*/

#error {
  @extend .content-wrapper;
}
