/* site-footer
----------------------------------------------*/

#site-footer {
  background: $main-color;
  color: white;
  a:link, a:visited {
    color: white;
  }
  a:hover, a:active {
    color: shade(white, 15%);
  }
  nav {
    width: 486px;
    .menus li {
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 1;
      & > ul {
        margin-top: 1rem;
      }
      &:not(:last-child) {
        margin-bottom: 3rem;
      }
    }
  }
}

#site-footer-inner {
  max-width: $max-width;
  margin: 0 auto;
  @include clearfix;
}

#magazines {
  $border-size: 7px;
  $list-margin: 28px;
  $image-width: 160px;
  font-weight: 300;
  text-align: left;
  width: ($image-width + $border-size * 2) * 2 + $list-margin;
  li {
    display: inline-block;
    width: $image-width + $border-size * 2;
  }
  li:not(:last-child) {
    margin-right: $list-margin;
  }
  .magazine-wrapper {
    background: white;
    display: block;
    margin-bottom: 2rem;
  }
  img {
    width: $image-width;;
    height: 226px;
    border: $border-size solid white;
    box-sizing: content-box;
    display: block;
    &:hover {
      opacity: 0.85;
    }
  }
  .about {
    line-height: 3rem;
    font-size: 1.3rem;
    letter-spacing: -0.025rem;
  }
  .title {
    font-size: 1.6rem;
    a {
      &:hover::after {
      }
    }
  }
  .details {
    font-size: 1.4rem;
  }
}

#copyright {
  line-height: 5.8rem;
  height: 8.8rem;
  border-top: 1px solid $border-color-strong;
  text-align: center;
  font-weight: 300;
  font-size: 1.4rem;
  color: white;
  clear: both;
  max-width: $max-width;
  margin: 0 auto;
}

/* site-footer for partners
----------------------------------------------*/

body[id^='page-partner'] {
  #site-footer {
    .logo-wrapper {
      border: none;
      padding: 0;
      margin: 0;
    }
  }
  #site-footer-inner {
    padding: 5.3rem 0 4rem;;
  }
}
