/* reset
----------------------------------------------*/

* {
  box-sizing: border-box;
}

html, body, div, span, p, a, del, em, img, ol, ul, li, fieldset, form, label, table, tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

html {
  font-size: $default-font-size;
}

html, body {
  line-height: 1;
  min-height:100%;
}

body {
  font-size: 1.6rem;
}

h1, h2, h3, h4, h5 {
  font-weight: 400;
  margin: 0;
}

ol, ul {
  list-style: none;
}

dt, dd {
  margin: 0;
}

img {
  max-width: 100%;
  height: auto;
  width: auto;
}

em, address {
  font-style: normal;
}

:focus {
  -webkit-appearance: none;
  outline-style: dotted;
  outline-width: 0px;
  outline-color: rgba(white, 0.5);
}

input[type='submit'] {
  border: none;
  font-family: 'Noto Sans Japanese', sans-serif;
}

input[type='submit'],
input[type='reset']
{
  border-radius: 0;
}
