/* slick-override
----------------------------------------------*/

.slick-prev
, .slick-next {
  background: white;
  border-radius: 1.5rem;
  width: 2.6rem;
  height: 2.6rem;
  padding: 0;
  z-index: 10;
  &::before {
    font-family: 'Font Awesome 6 Free';
    display: inline-block;
    margin: -0.2rem;
    color: black;
    font-size: 3rem;
    font-weight: 900;
  }
  &:hover,
  &:focus {
    background: white;
  }
}

.slick-prev {
  left: 0;
}

.slick-next {
  right: 0;
}

.slick-prev::before {
  content: '\f137';
}

.slick-next::before {
  content: '\f138';
}

button
, *[type='button'] {
  transition: none 0;
}

.slick-dots {
  padding: 2rem 0;
  left: 0;
  bottom: -6.2rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  li {
    margin: 0 0.1rem;
    button {
      position: relative;
      &::before {
        @include size(0.6rem, 0.6rem);
        position: absolute;
        content: '';
        display: block;
        background: black;
        border-radius: 50%;
        top: calc(50% - 0.3rem);
        left: calc(50% - 0.3rem);
      }
    }
  }
}

 #open-house-and-events
, #recommended-experts
, #recommended-articles
, #news-and-open-house
{
  @include grid-media($larger-max-width) {
    .slider {
      margin: 0 auto;
      width: 100%;
    }
  }
  @include grid-media($smaller-max-width) {
    .slider {
      margin: 0 2rem;
      max-width: calc(100% - 4rem);
      pading: 0;
    }
  }
}

#page-home-mobile #open-house-and-events {
  @include grid-media($smaller-max-width) {
    .slider {
      max-width: 100%;
    }
    .slick-list {
      margin: 0 4rem;
    }
    .slick-prev {
      left: 1rem;
    }
    .slick-next {
      right: 1rem;
    }
  }
}

#hero,
#banners {
  .slider,
  .slick-prev,
  .slick-next {
    visibility: hidden;
  }
}

.fade {
  visibility: visible;
  animation-name: fade-in;
  animation-duration: 2s;
  animation-timing-function: ease-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
}

@keyframes fade-in {
  0% {opacity: 0}
  100% {opacity: 1}
}
