/* site-bread-crumbs
----------------------------------------------*/

@import 'with-arrow';

#site-bread-crumbs {
  ul {
    margin: 0 auto;
    max-width: $max-width;
    padding: 2rem 0;
  }
  li {
    display: inline-block;
    font-size: 1.3rem;
    line-height: 1;
    &:not(:last-child)::after {
      @include with-arrow(right, black);
      display: inline-block;
      right: 0;
      margin: 0 1em + calc(1em * $sqrt2 / 2) 0 1em;
    }
  }
}
