/* visitors
----------------------------------------------*/

#hero
, #open-house-and-events
, #recommended-past-works
, #recommended-articles
, #page-home #news
{
  padding-bottom: 9rem;
  h1 {
    text-align: center;
    padding: 4rem;
    font-size: 2.2rem;
    font-weight: 700;
  }
}

#page-home #find-experts-first-step
, #recommended-articles
{
  background: $base-color;
}

#hero {
  height: 56.5rem;
  .slick-prev,
  .slick-next {
    z-index: 100;
    &::before {
      color: $main-color-clear;
    }
  }
  .slick-prev {
    left: calc(50% - 75rem / 2 - 3rem);
  }
  .slick-next {
    right: calc(50% - 75rem / 2 - 3rem);
  }
  .slick-dots {
    bottom: 9rem;
    li button:before {
      background: #ADADAD;
    }
    li.slick-active button:before {
      background: #FFFFFF;
    }
  }
  @keyframes zoomOut {
    0% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1.0);
    }
  }
  .add-animation {
    animation: zoomOut 8s linear 0s normal both;
  }
}

#recommended-past-works
, #recommended-articles
, #find-experts-first-step
, #open-house-and-events
{
  h1 {
    text-align: center;
    padding: 2.5rem 0 4.5rem;
    * {
      display: inline-block;
      vertical-align: middle;
    }
    img {
      @include size(auto, 9.3rem);
      margin: 0 2rem -2rem 0;
    }
  }
}

#recommended-past-works
, #recommended-articles
{
  .slick-list {
    padding: 0 !important;
    @extend .cropped-photo-large;
  }
  .slider-element {
    text-align: center;
    font-size: 1.4rem;
    a {
      width: 300px;
      display: inline-block;
      margin: 0 auto;
      text-align: left;
    }
  }
}

#recommended-past-works {
  letter-spacing: 0.1em;
  .company {
    font-size: 1.3rem;
    letter-spacing: 0.025em;
  }
  .slick-prev,
  .slick-next {
    top: calc(20rem / 2);
  }
}

#recommended-articles {
  letter-spacing: 0.025em;
  h2 {
    font-size: 1.4rem;
  }
  time {
    display: block;
    margin: 1rem 0 0 0;
  }
  .slick-prev,
  .slick-next {
    top: calc(20rem / 2 + 2.1rem);
  }
}

#past-work-photos {
  .slick-prev,
  .slick-next {
    top: 12rem;
  }
}

#open-house-and-events {
  .link-to-open-house-and-events {
    text-align: center;
    margin: 0 auto 2rem;
    font-size: 1.6rem;
    i {
      margin: 0 0.1em;
    }
  }
  .label {
    display: inline-block;
    background: $main-color-clear;
    color: white;
    font-weight: 500;
    line-height: 1;
    padding: 0.4rem;
  }
  .prefecture {
    font-weight: 500;
  }
  .slick-prev,
  .slick-next {
    top: 50%;
  }
  .actions {
    text-align: center;
    a {
      @include size(27rem, 5rem);
      @extend .accent-color-button;
      display: inline-block;
      line-height: 5rem;
    }
  }
}

#page-home #news {
  ul {
    margin: 0 auto;
    width: 100%;
    max-width: $max-width;
    border-top: 1px solid $border-color-demilight;
  }
  li {
    border-bottom: 1px solid $border-color-demilight;
    padding: 2rem;
    text-align: center;
    font-size: 1.5rem;
  }
  .news-inner {
    max-width: calc(100% - 3rem);
    margin: 0 auto;
    text-align: left;
    display: flex;
    & > div {
      flex-basis: calc(100% - 10rem);
    }
  }
  time {
    flex-basis: 18rem;
  }
}
