/* with-triangle
----------------------------------------------*/

@mixin with-triangle($direction: right, $color: black) {
  content: "";
  width: 0;
  height: 0;
  display: inline-block;
  position: absolute;
  @if $direction == 'right' {
    border-top: 0.5em solid transparent;
    border-bottom: 0.5em solid transparent;
    border-right: 0.5em solid transparent;
    border-left: 0.9em solid $color;
  }
  @if $direction == 'down' {
    border-top: 0.9em solid $color;
    border-right: 0.5em solid transparent;
    border-left: 0.5em solid transparent;
  }
  @if $direction == 'left' {
    border-top: 0.5em solid transparent;
    border-right: 0.9em solid $color;
    border-bottom: 0.5em solid transparent;
  }
  @if $direction == 'up' {
    border-right: 0.5em solid transparent;
    border-bottom: 0.9em solid $color;
    border-left: 0.5em solid transparent;
  }
}
