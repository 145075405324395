/* results list
----------------------------------------------*/

*[id^='page-favorite']
, #page-company {
  #results-list {
    li {
      border: 1px solid $border-color;
      clear: both;
      margin: 0 0 2rem 0;
      display: flex;
    }
    .check {
      @extend .default-checkbox;
      background: $base-color;
      flex-basis: 4.6rem;
      text-align: center;
      input[type=checkbox] + label {
        font-size: 1rem;
        line-height: 1.3em;
        display: block;
        color: #747474;
        white-space: nowrap;
        margin: 0;
        padding: 0;
        &::before {
          margin: 1rem auto;
          display: block;
          position: static;
        }
      }
      input[type=checkbox]:checked + label {
        &::after {
          display: block;
          position: static;
          margin: -5rem auto 0;
        }
      }
    }
    .summary {
      float: right;
      border-left: 1px solid $border-color;
      background: white;
      width: calc(100% - 4rem);
      position: relative;
      padding: 0 2rem 2rem 2rem;
      h3 {
        font-size: 2rem;
        font-weight: 500;
        line-height: 1.5;
        border-bottom: 1px solid $border-color;
        padding: 3rem 0;
        margin: 0 0 3rem 0;
      }
    }
    .icon {
      float: left;
      margin-right: 2rem;
      @include size(6.2rem, 6.2rem);
    }
    .area {
      font-size: 1.4rem;
      font-weight: 700;
      height: 1.5em;
      overflow-y: hidden;
      position: absolute;
      bottom: 4rem;
      left: calc(251px + 2rem + 3rem);
      span {
        font-weight: 400;
      }
    }
    .link-to-details {
      @extend .accent-color-button;
      position: absolute;
      right: 2rem;
      top: 2rem;
      padding: 0.8rem 2rem;
    }
    .past-works {
      @extend .cropped-photo-results;
      float: left;
      margin: 0 3rem 0 0;
    }
    .representative {
      height: 62px;
      line-height: 62px;
      margin-bottom: 2rem;
      width: 100%;
      font-size: 1.4rem;
    }
    .description {
      font-size: 1.3rem;
      height: 1.5em * 2;
      overflow-y: hidden;
      margin-bottom: 2rem;
    }
    .favorite {
      text-align: right;
      font-size: 1.4rem;
      font-weight: 700;
      position: absolute;
      bottom: 2rem;
      right: 2rem;
      .add {
        color: rgba($main-color-clear, 0.4);
      }
      .remove {
        i {
          color: $accent-color;
        }
      }
    }
  }
}

div[id^='actions-for-results'] {
  display: flex;
  width: 100%;
  margin-bottom: 2rem;
}

.check-all-container
, .request-all-container {
  height: 6.3rem;
  line-height: 6.3rem;
  font-size: 1.4rem;
  font-weight: 700;
  text-align: center;
}

.check-all-container {
  @extend .default-checkbox;
  background: $accent-color;
  flex-basis: 24rem;
  position: relative;
}

#actions-for-results-top {
  .check-all-container::after {
    @include with-triangle(down, $accent-color);
    left: 1.8rem;
    bottom: -0.6em;
  }
}

#actions-for-results-bottom {
  .check-all-container::before {
    @include with-triangle(up, $accent-color);
    left: 1.8rem;
    top: -0.6em;
  }
}

.request-all-container {
  background: $base-color;
  flex-basis: 100%;
}

#check-all-top:checked,
#check-all-bottom:checked {
  & + label {
    &::before {
      background: white;
      border: 1px solid $border-color-demilight;
    }
  }
}
