/* common
----------------------------------------------*/
// FIXME
// 応急処置。なぜかここで読み込まないと動かなくなってしまった。
@import 'devices';

body {
  color: $default-font-color;
  background: $site-bg;
  /*
  font-family: "Kozuka Mincho Pro","ヒラギノ明朝 ProN W3","Hiragino Mincho Pro","HiraMinProN-W3","游明朝","Yu Mincho","游明朝体","YuMincho","ＭＳ Ｐ明朝","MS PMincho",serif; */
  font-family: 'Noto Sans Japanese', sans-serif;
  font-size: 62.5%;
  font-weight: 400;
  line-height: 1.5;
  //padding-top: $fixed-header-height;
}

table {
    border-collapse: collapse;
}


/* link
----------------------------------------------*/

a {
  text-decoration: none;
  &:link, &:visited {
    color: $main-color-clear;
  }
  &:hover, &:active {
    color: tint($main-color-clear, 30%);
  }
}


/* radio, checkbox, select, search, textarea
----------------------------------------------*/


input, textarea, select, button {
  font-family: 'Noto Sans Japanese', sans-serif;
}

.appearance-none {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input {
  @extend .appearance-none;
}

.default-radio
, .default-checkbox {
  input[type=radio]
  , input[type=checkbox] {
    display: none;
    margin: 0;
    & + label {
      position: relative;
      margin-right: 12px;
      line-height: 30px;
      cursor: pointer;
      padding: 0 0 0 24px;
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        margin-top: -0.9rem;
        left: 0;
        padding-top: calc((3rem - 1.8rem) / 2);
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        display: block;
        width: 18px;
        height: 18px;
        background: #FFF;
      }
    }
    &:checked + label::before {
      background: $accent-color;
      border: none;
    }
    &:checked + label::after {
      content: "";
      position: absolute;
      top: 50%;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      display: block;
    }
  }
  input[type=radio] {
    & + label::before {
      border: 1px solid #ccc;
      border-radius: 3rem;
    }
    &:checked + label::after {
      left: 5px;
      width: 8px;
      height: 8px;
      margin-top: -4px;
      background: $main-color-clear;
      border-radius: 8px;
    }
  }
  input[type=checkbox] {
    @include size(1.6rem, 1.6rem);
    line-height: 1.5;
    & + label::before {
      border: 1px solid #ccc;
    }
    &:checked + label::after {
      left: 3px;
      width: 16px;
      height: 8px;
      margin-top: -8px;
      border-left: 3px solid $main-color-clear;
      border-bottom: 3px solid $main-color-clear;
      -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
  }
}

input[type='text']
, input[type='password']
, input[type='email']
, select {
  border: 1px solid $main-color-clear;
  padding: 0 0.5em;
  height: 3rem;
  line-height: 3rem;
  font-size: 1.3rem;
}

select {
  @extend .appearance-none;
  font-size: 1.4rem;
  line-height: 3.4rem;
  height: 3.4rem;
}

select::-ms-expand {
  display: none;
}

.select-wrapper {
  position: relative;
  &::before {
    @include with-arrow(down, #707070);
    @include arrow-position(top, right, calc((3rem - 1.3rem) / 2), 1em);
    position: absolute;
    //top: calc(0.8rem + 0.25em);
    //right: 1rem;
    pointer-events: none;
  }
  select {
    padding-right: 4rem;
  }
}

.select-wrapper-with-triangle {
  position: relative;
  &::before {
    font-size: 1rem;
    @include with-triangle(down, black);
    position: absolute;
    top: calc(50% - 0.9em / 2);
    right: 1em;
    pointer-events: none;
  }
  select {
    padding-right: 4rem;
  }
}

select {
  padding-left: 1rem;
  background: white;
}

select
,input[type="search"] {
  border: 1px solid $main-color-clear;
  height: 3rem;
  line-height: 3rem;
  border-radius: 0;
}

input[type="search"] {
  padding: 0 1.6rem;
  font-size: 1.2rem;
}

textarea {
  border: 1px solid $main-color-clear;
  line-height: 1.5;
  padding: 0.5em;
  font-size: 1em;
}

form {
  .calendar
  , .separater
  , .unit
  , .from-to {
    display: block;
    padding: 0 1em;
    height: 3.2rem;
    line-height: 3.2rem;
    text-align: center;
  }
}

#agreed-to-privacy-policy {
  position: relative;
  line-height: 1;
  display: inline-block;
  &::before {
    content: "";
    position: relative;
    left: 0;
    top: 0.4rem;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    display: inline-block;
    width: 1.8rem;
    height: 1.8rem;;
    margin-top: -9px;
    background: $accent-color;
    margin-right: 0.5em;
  }
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    display: block;
    left: 3px;
    width: 16px;
    height: 8px;
    margin-top: -8px;
    border-left: 3px solid $main-color-clear;
    border-bottom: 3px solid $main-color-clear;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
}

.my-inline {
  display: flex;
  width: 100%;
  label {
    margin-right: 1rem;
    white-space: nowrap;
    line-height: 3rem;
  }
  .header {
    margin-right: 1rem;
  }
}


/* required label
----------------------------------------------*/

.required {
  background: $accent-color;
  padding: 0 0.7rem;
  margin: 0.6rem 0;
  line-height: 3rem - 0.6rem * 2;
  letter-spacing: 0.1em;
}


/* with sidebar
----------------------------------------------*/

*[id^='page-favorite'] #favorite,
#page-news #news,
#open-house-and-events-details,
#open-house-and-events-search,
#companies-search,
#past-works-search {
  display: flex;
  #site-main-container {
    flex-basis: calc(100% - 29rem);
    padding-right: 4.5rem;
  }
  #site-sidebar {
    padding-bottom: 2rem;
    flex-basis: 29rem;
  }
}

#page-news
, *[id^='page-favorite'] {
  #site-sidebar {
    padding-top: 6rem;
    font-size: 1.4rem;
  }
}


/* new label
----------------------------------------------*/

.new {
  background: $accent-color;
  font-size: 1.4rem;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  line-height: 2.3rem;
  width: 4.2rem;
  display: inline-block;
  letter-spacing: 0;
  z-index: 2;
}

#products-list,
#past-works-list {
  .new {
    position: absolute;
    top: 0;
    left: 0;
  }
  & > ul > li,
  .slick-list li {
    position: relative;
  }
}


/* category label
----------------------------------------------*/

.category {
  background: $accent-color;
  font-weight: 500;;
  text-align: center;
}


/* more-button
----------------------------------------------*/

.more {
  text-align: center;
  font-size: 1.4rem;
  font-weight: 500;
  padding: 4rem 0;
}

@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.open-more {
  display: none;
  & + label {
    font-size: 1.4rem;
    font-weight: 500;
    display: block;
    text-align: center;
    margin: 2rem auto;
    & + .more-contents {
      display: none;
    }
  }
  &:checked {
    & + label {
      display: none;
      & + .more-contents {
        display: block;
        animation: show 1s linear 0s;
      }
    }
  }
}

/* details header and table
----------------------------------------------*/

.details-header {
  font-size: 1.5rem;
  font-weight: 500;
  letter-spacing: 0.1em;;
  line-height: 3;
  border-bottom: 1px solid $main-color-clear;
  margin: 6rem 0 0 0;
  clear: both;
}

.details-table {
  width: 100%;
  tr {
    border-bottom: 1px solid #ccc;
  }
  th {
    width: calc(5em + 0.1rem * 5 + 1em + 2em);
    padding: 1em 2em 1em 1em;
  }
  th, td {
    font-size: 1.6rem;
    font-weight: 400;
    letter-spacing: 0.1rem;
    text-align: left;
    height: 100%;
    vertical-align: middle;
  }
  td {
    position: relative;
    padding: 1em;
  }
  td {
    word-break: break-all;
    &::before {
      display: inline-block;
      @include size(1px, 1em);
      content: '';
      background: $main-color-clear;
      position: absolute;
      left: -1em;
      top: calc(50% - 0.5em);
    }
  }
}

.details-list {
  font-size: 1.4rem;
  letter-spacing: 0.1em;
  margin: 2rem 0 3rem;
  li {
    margin-bottom: 2rem;
  }
  h4 {
    color: #666;
    font-size: 1.5rem;
    margin-bottom: 0.4rem;
  }
}


/* icon
----------------------------------------------*/

.icon {
  img {
    object-fit: cover;
    border-radius: 50%;
    @include size(100%, 100%);
    font-family: 'object-fit: cover;';
  }
}


/* error message
----------------------------------------------*/

.error-header {
  font-size: 1.4rem;
  font-weight: 500;
  color: $highlight-color;
  margin-bottom: 3rem;
}

.error-message  {
  font-size: 1.2rem;
  color: $highlight-color;
}


/* for @extend
----------------------------------------------*/

.small-container {
  max-width: 94.5rem + 4rem;
  margin: 0 auto;
  padding: 2rem;
}

.accent-color-button {
  background: $accent-color;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
  border: none;
  &:hover, &:active {
    background: tint($accent-color, 10%);
    color: $main-color-clear;
  }
  &[disabled="disabled"] {
    background: #F0F0F0;
    color: #767676;
  }
}

.user-actions-base {
  font-size: 1.4rem;
  i {
    color: white;
  }
  li {
    display: inline-block;
    margin-left: 1em;
  }
  a, button {
    display: block;
    width: 172px;
    text-align: center;
    font-weight: 700;
  }
  a:link, a:visited {
    background: $accent-color;
  }
  a:hover, a:active {
    background: tint($accent-color, 10%);
  }
  .favorite {
    a, button, .button {
      border: 1px solid $border-color-demilight;
      background: white;
    }
    .button {
      cursor: pointer;
    }
    .add {
      .fa-star {
        color: $main-color-clear;
      }
    }
    .remove {
      .fa-star {
        color: $accent-color;
      }
    }
  }
}

/* favorite star
----------------------------------------------*/

*[id^='page-favorite'],
*[id^='page-past-works'],
*[id^='page-company']
{
  #results-list,
  #past-works-list
  {
    .favorite {
      font-size: 1.4rem;
      font-weight: 700;
      position: absolute;
      .add {
        color: rgba($main-color-clear, 0.4);
      }
      .remove {
        .fa-star {
          color: $accent-color;
        }
      }
    }
  }
}

#page-favorite-experts-mobile #results-list,
#page-favorite-past-works #past-works-list,
#page-past-works #past-works-list,
#page-past-works #results-list,
#page-past-works-search, #past-works-list,
#page-company-mobile #past-works-list
{
  li {
    .favorite {
      font-size: 2.2rem !important;
    }
  }
}

.default-ul,
.default-ol {
  padding: 0 0 0 20px;
}

.default-ul {
  list-style-type: disc;
}

.default-ol {
  list-style-type: decimal;
}


/* note
----------------------------------------------*/

ul.note li,
div.note,
span.note {
  padding-left: 1.3em;
  text-align: left;
  position: relative;
  &::before {
    content: '※';
    margin-bottom: 0.5em;
    position: absolute;
    left: 0;
  }
}

span.note {
  margin-left: 1rem;
}


/* error pages
----------------------------------------------*/

#error {
  text-align: center;
  font-weight: 500;
  .logo {
    margin: 5rem;
    img {
      @include size(16.4rem, auto);
    }
  }
  h1 {
    margin-bottom: 5rem;
    @include grid-media($larger-mini-tablet) {
      font-size: 2rem;
    }
    @include grid-media($smaller-mini-tablet) {
      font-size: 1.8rem;
    }
  }
  .message {
    font-size: 1.5rem;
    @include grid-media($larger-mini-tablet) {
      text-align: center;
    }
    @include grid-media($smaller-mini-tablet) {
      text-align: left;
      padding: 0 1rem;
    }
  }
  .back-to-home {
    a {
      @extend .accent-color-button;
      height: 5rem;
      line-height: 5rem;
      text-align: center;
      display: block;
    }
    @include grid-media($larger-mini-tablet) {
      @include size(27rem, 5rem);
      margin: 8rem auto 11rem;
    }
    @include grid-media($smaller-mini-tablet) {
      font-size: 1.4rem;
      width: 100%;
      max-width: 37.5rem;
      padding: 0 1rem;
      margin: 4rem auto 7rem;
    }
  }
}


/* cropped photo
----------------------------------------------*/

.cropped-photo-large {
  img {
    @include size(30rem, 20rem);
    object-fit: cover;
    font-family: 'object-fit: cover;';
  }
}

.cropped-photo-medium {
  img {
    @include size(22.5rem, 15rem);
    object-fit: cover;
    font-family: 'object-fit: cover;';
  }
}

.cropped-photo-small {
  img {
    @include size(10rem, 10rem);
    @media (max-width: 320px) {
      @include size(8rem, 8rem);
    }
    object-fit: cover;
    font-family: 'object-fit: cover;';
  }
}

.cropped-photo-results {
  img {
    @include size(25.1rem, 16.7rem);
    object-fit: cover;
    font-family: 'object-fit: cover;';
  }
}

.cropped-photo-sidebar {
  img {
    @include size(10rem, 6.6rem);
    object-fit: cover;
    font-family: 'object-fit: cover;';
  }
}


/* for ellipsis
----------------------------------------------*/

.ellip {
  display: block;
  height: 100%;
}

.ellip-line {
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  max-width: 100%;
}

.ellip,
.ellip-line {
  position: relative;
  overflow: hidden;
}


/* cover
----------------------------------------------*/

.first-photo,
#description .photo,
#past-works-list .photo,
#results-list .photo,
#results-list .past-works,
#recommended-past-works .photo,
#products-list .thumbnail,
#products-list .max-size-photo,
.photo-container {
  position: relative;
}

.cover {
  @include size(100%, 100%);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
