/* results-header
----------------------------------------------*/

#results-header {
  @include clearfix;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.5rem;
  margin-top: 2rem;
  .condition {
    color: #666;
  }
  .results {
    float: right;
  }
  .number {
    color: $highlight-color;
    font-size: 2.5rem;
  }
}

*[id^='page-favorite'] {
  #results-header {
    margin: 2rem 0 3rem 0;
  }
}

#page-past-works-search
, #page-favorite-past-works {
  #results-header {
    border-bottom: 1px solid $border-color;
    padding-bottom :1rem;
    margin: 2rem 0 2.6rem 0;
  }
}
