/* page-header
----------------------------------------------*/

#page-header {
  background: $accent-color;
  padding: 2.4rem 0;
  h1 {
    font-weight: 400;
    letter-spacing: 0.1em;
    max-width: $max-width;
    margin: 0 auto;
    font-size: 2.4rem;
  }
  span {
    font-size: 1.4rem;
    font-weight: 500;
    margin-left: 6rem;
  }
}

#page-company
, #page-guide
, *[id^='page-open-house-and-events']
, *[id^='page-past-works'] {
  #page-header {
    h1 {
      position: relative;
      &::before {
        @include with-triangle(up, $accent-color);
        top: calc(-2.4rem - 0.5em);
      }
    }
  }
}

*[id^='page-favorite'] #favorite-header
, #page-privacy-policy #privacy-policy-header
, #page-about #about-header
, *[id^='page-general-contact'] #general-contact-header
, #page-news #news-header {
  font-size: 2.4rem;
  font-weight: 500;
  letter-spacing: 0.1em;
  margin: 6rem 0;
  span {
    font-weight: 400;
    margin-left: 4rem;
    font-size: 1.6rem;
  }
}

.simple-header {
  padding: 1.8rem;
  background: $base-color;
  font-size: 1.8rem;
  font-weight: 500;
  letter-spacing: 0.05em;
  margin: 8rem 0 3rem;
}
