.iloie-component {
  font-weight: 400;
  .message {
    margin-bottom: 6rem;
    text-align: center;
    font-size: 1.6rem;
    letter-spacing: 0.05em;
  }

  .form-actions {
    text-align: center;
    position: relative;
    a {
      &:link, &:hover, &:active, &:visited {
        color: $main-color-clear;
      }
    }
  }
  .btn {
    display: inline-block;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.4;
    user-select: none;
    padding: 0.6rem 1.2rem;
    font-size: 1.4rem;
    border: none;
    vertical-align: baseline;
  }
  .primary-button {
    @extend .accent-color-button;
  }
  .secondary-button {
    background: $base-color;
    &:hover, &:active {
      background: tint($base-color, 15%);
    }
  }

  .sub-menu {
    nav {
      background: $base-color;
      height: 8rem;
    }
    .nav-inner {
      position: relative;
      margin: 0 auto;
      width: $max-width; // 幅固定
    }

    .menu {
      position: relative;
      margin: 0 auto;
      max-width: 1000px;
      ul {
        li {
          display: inline-block;
          a {
            line-height: 8rem;
            display: block;
            padding: 0 2rem;
            position: relative;
            font-size: 1.5rem;
            font-weight: 500;
          }
        }
        .active {
          a {
            background: $accent-color;
            &::after {
              @include with-triangle(down, $accent-color);
              top: 8rem;
              left: calc(50% - 0.5em);;
            }
          }
        }
      }
    }
  }

  .side-menu {
    padding: 3.5rem 0 0 0;
    border-right: 1px solid $border-color-demilight;
    li {
      a {
        font-size: 1.5rem;
        font-weight: 500;
        display: block;
        line-height: 5.2rem;
      }
    }
    .active {
      a {
        color: #8D8D8D;
        position: relative;
        &::before {
          @include with-arrow(right, #8D8D8D);
          display: inline-block;
          margin-right: 0.5em;
        }
      }
    }
  }

  .photo-list {
    @extend .cropped-photo-large;
    li {
      margin: 1.75rem 0;
      flex-basis: calc((100% - 3.5rem * 2) / 3);
      &:not(:nth-child(3n)) {
        margin-right: 3.5rem;
      }
      .favorite {
        font-size: 2.2rem;
        top: 21rem;
        right: 1rem;
        position: absolute;
        z-index: 10;
      }
    }
    margin: 0 auto;
    padding: 2rem 0 2.4rem 0;
    @include clearfix;
    h1 {
      padding: 1rem 0;
      margin-bottom: 4rem;
      font-size: 1.5rem;
      font-weight: 400;
      letter-spacing: 0.2rem;
    }
    ul {
      display: flex;
      flex-wrap: wrap;
    }
    li {
      background: $base-color;
      display: inline-block;
      margin: 2.4rem;
      position: relative;
      flex-basis: 30rem;
      & > a, & > label {
        display: block;
        box-sizing: content-box;
        height: 100%;
        &:hover, &:active {
          border: 3px solid $accent-color;
          margin: -3px;
        }
      }
    }
    h2 {
      font-size: 1.4rem;
      font-weight: 400;
      height: 6rem;
      margin: 0;
    }
    .number {
      font-size: 1.2rem;
      padding: 0 1.4rem 1.4rem;
    }
    .company {
      font-size: 1.3rem;
      text-align: right;
      color: #707070;
      display: block;
      padding: 0 1.4rem 1.4rem;
    }
    .new {
      position: absolute;
      top: 0;
      left: 0;
    }

    li {
      h2 {
        width: calc(100% - 2.5rem);
      }
    }

    li {
      margin: 2.4rem;
    }
    .favorite {
      top: 20rem;
      right: 1rem;
    }

    h2 {
      padding: 1em;
    }
  }

  .form-element {
    @extend .default-radio;
    @extend .default-checkbox;
    color: #707070;
    margin-bottom: 3rem;
    width: 71rem;
    .header {
      font-size: 1.5rem;
      font-weight: 500;
      line-height: 3rem;
      color: $main-color-clear;
      .note {
        color: #707070;
        font-size: 1.3rem;
      }
      span.note {
        margin: 0 2rem;
      }
    }
    .url {
      margin-top: 3rem;
      .header {
        font-size: 1.3rem;
        color: #707070;
      }
    }
    .required {
      color: $main-color-clear;
      font-size: 1.3rem;
    }
    .comment {
      color: #707070;
      font-size: 1.3rem;
      margin: 1.5rem 0;
    }
    .select-wrapper {
      display: inline-block;
    }
    .area-selector {
      label
      , .select-wrapper {
        margin-right: 1rem;
      }
    }
  }

  .company-list {
    li {
      border: 1px solid $border-color;
      clear: both;
      margin: 0 0 2rem 0;
      display: flex;
    }
    .check {
      @extend .default-checkbox;
      background: $base-color;
      flex-basis: 4.6rem;
      text-align: center;
      input[type=checkbox] + label {
        font-size: 1rem;
        line-height: 1.3em;
        display: block;
        color: #747474;
        white-space: nowrap;
        margin: 0;
        padding: 0;
        &::before {
          margin: 1rem auto;
          display: block;
          position: static;
        }
      }
      input[type=checkbox]:checked + label {
        &::after {
          display: block;
          position: static;
          margin: -5rem auto 0;
        }
      }
    }
    .summary {
      float: right;
      border-left: 1px solid $border-color;
      background: white;
      width: calc(100% - 4rem);
      position: relative;
      padding: 0 2rem 2rem 2rem;
      h3 {
        font-size: 2rem;
        font-weight: 500;
        line-height: 1.5;
        border-bottom: 1px solid $border-color;
        padding: 3rem 0;
        margin: 0 0 3rem 0;
      }
    }
    .icon {
      float: left;
      margin-right: 2rem;
      @include size(6.2rem, 6.2rem);
    }
    .area {
      font-size: 1.4rem;
      font-weight: 700;
      height: 1.5em;
      overflow-y: hidden;
      position: absolute;
      bottom: 4rem;
      left: calc(251px + 2rem + 3rem);
      span {
        font-weight: 400;
      }
    }
    .link-to-details {
      @extend .accent-color-button;
      position: absolute;
      right: 2rem;
      top: 2rem;
      padding: 0.8rem 2rem;
    }
    .past-works {
      @extend .cropped-photo-results;
      float: left;
      margin: 0 3rem 0 0;
      position: relative;
    }
    .representative {
      height: 62px;
      line-height: 62px;
      margin-bottom: 2rem;
      width: 100%;
      font-size: 1.4rem;
    }
    .description {
      font-size: 1.3rem;
      height: 1.5em * 2;
      overflow-y: hidden;
      margin-bottom: 2rem;
    }
    .favorite {
      text-align: right;
      font-size: 1.4rem;
      font-weight: 700;
      position: absolute;
      bottom: 2rem;
      right: 2rem;
      .add {
        color: rgba($main-color-clear, 0.4);
      }
      .remove {
        color: $accent-color;
      }
    }
  }
}
