/* pagination
----------------------------------------------*/

.pagination {
  color: $main-color-clear;
  font-size: 1.6rem;
  ol {
    margin: 3.5rem 0;
    text-align: center;
  }
  li {
    @include size(2.4rem, 2.4rem);
    display: inline-block;
    line-height: 2.4rem;
    text-align: center;
    margin: 1rem 0.1rem;
  }
  a {
    display: block;
  }
  a:hover, a:active {
    color: tint($main-color-clear, 35%);
  }
  .current {
    background: $accent-color;
  }
  .disabled {
    color: #BABABA;
  }
}
