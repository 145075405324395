/* login
----------------------------------------------*/

#login {
  margin: 5rem auto;
  a:link, a:visited {
    color: #919191;
  }
  a:hover, a:active {
    color: tint(#919191, 20%);
  }
  .message
  , .error-message {
    font-size: 1.3rem;
    font-weight: 500;
    margin-bottom: 2rem;
  }
}

#login-header {
  font-size: 2rem;
  font-weight: 500;
  margin: 4rem 0;
}

#login-form {
  font-size: 1.3rem;
  input[type="email"]
  , input[type="password"]  {
    font-size: 1.4rem;
    height: 4rem;
    line-height: 4rem;
    padding: 0 0.5em;
    width: 100%;
    margin-bottom: 2rem;
    border: 1px solid #B9B9B9;
    &:focus {
      outline: 2px solid $accent-color;
    }
  }
  label {
    font-weight: 500;
    line-height: 2;
  }
}

#remember-me {
  @extend .default-checkbox;
}

.submit {
  width: 100%;
  text-align: center;
  a,
  input[type="submit"] {
    @extend .accent-color-button;
    font-weight: 700;
    width: 100%;
    height: 5rem;
    line-height: 5rem;
    margin: 1rem 0;
  }
  a {
    display: block;
  }
}

#login-options {
  font-size: 1.3rem;
  font-weight: 500;
  color: #919191;
  li {
    margin-bottom: 1em;
  }
}
