/* contact
----------------------------------------------*/

#general-contact,
#contact {
  @extend .small-container;
  font-weight: 500;
  .message {
    margin-bottom: 6rem;
    text-align: center;
    font-size: 1.6rem;
    letter-spacing: 0.05em;
  }
}

#general-contact {
  .message {
    text-align: left;
  }
}

#customer-information {
  th, td {
    vertical-align: top;
    font-size: 1.3rem;
  }
  th {
    text-align: right;
    border-right: 1px solid $border-color;
    padding: 1.25rem 2rem 1.25rem 0;
    position: relative;
    min-width: 27.8rem;
    line-height: 3rem;
  }
  td {
    $padding: calc((3rem - 1.3rem * 1.5) / 2) + 1.25rem;
    padding: $padding 0 $padding 2rem;
    width: 64rem;
  }
  em {
    color: $highlight-color;
  }
  input[type="text"]
  , textarea {
    width: 100%;
  }
  input[type="text"]
  , input[type="number"]
  , input[type="phone"]
  , input[type="email"]
  , textarea
  , select {
    border: 1px solid $border-color-strong;;
  }
  textarea {
    height: 1em * 1.5 * 3 + 0.5em * 2;
  }
  .required {
    position: absolute;
    left: 0;
  }
  .select-wrapper {
    display: inline-block;
  }
  select {
    width: 5em;
  }
  select#contact_age,
  select#general_contact_age,
  select#contact_prefecture {
    width: 8em;
  }
  .note-container {
    margin: 4rem 0 2rem 0;
    a:link, a:visited {
      color: $highlight-color;
    }
    a:hover, a:active {
      color: tint($highlight-color, 20%);
    }
  }
  .default-radio {
    li {
      margin-bottom: 2rem;
    }
  }
}


/* no checked
----------------------------------------------*/

#no-checked {
  font-weight: 500;
  color: $main-color-clear;
  background: url('../images/no-checked.svg') no-repeat right top;
  background-size: 34rem auto;
  padding-top: 3rem;
  margin-top: 7rem;
  p {
    font-size: 1.6rem;
    line-height: 2em;
    letter-spacing: 0.1em;
    margin-bottom: 20rem;
  }
}


/* thanks, send-error
----------------------------------------------*/

#thanks
, #send-error {
  font-weight: 500;
  color: $main-color-clear;
  background: url('../images/thanks.svg') no-repeat right top;
  background-size: 34rem auto;
  min-height: 34rem;
  h2 {
    font-size: 1.6rem;
    margin-bottom: 4rem;
    letter-spacing: 0.1rem;
  }
  p {
    font-size: 1.4rem;
    line-height: 2em;
    margin-bottom: 3em;
    letter-spacing: 0.05rem;
  }
}

#page-general-contact-thanks {
  #general-contact {
    background: url('../images/thanks-general.svg') no-repeat right 11.2rem top 8rem;;
    background-size: 27.7rem auto;
    min-height: 27.7rem;
  }
}

#thanks-general {
  color: $main-color-clear;
  h2 {
    font-size: 1.6rem;
    font-weight: 500;
    margin-bottom: 6rem;
    letter-spacing: 0.1em;
  }
  p {
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.8;
    margin-bottom: 1em * 1.8;
    letter-spacing: 0.1em;
  }
}


/* requested documents
----------------------------------------------*/

#requested-documents-header {
  font-size: 1.6rem;
  margin-bottom: 1rem;
  .total {
    font-weight: 400;
  }
  .number {
    color: $highlight-color;
    font-size: 2.5rem;
    margin: 0 1rem;
  }
}

#requested-documents {
  font-size: 1.4rem;
  border-collapse: collapse;
  color: #747474;
  width: 100%;
  margin-bottom: 6rem;
  th, td {
    border-top: 1px solid $border-color-demilight;
    border-bottom: 1px solid $border-color-demilight;
    padding: 1.2rem 3.6rem;
    text-align: left;
  }
  th:first-child {
    width: 16rem;
    padding: 1.2rem 2.8rem;
  }
  thead {
    background: #F0F0F0;
    th {
      vertical-align: middle;
    }
  }
  tbody {
    th {
      color: $main-color-clear;
    }
    th.default-checkbox {
      text-align: center;
    }
    td {
      font-size: 1.3rem;
      width: 21.7rem;
    }
  }
  th {
    border-right: 1px solid $border-color;
    &:last-child {
      border-right: none;
    }
  }
}


/* contact actions
----------------------------------------------*/

.contact-actions {
  text-align: center;
  position: relative;
  a {
    &:link, &:hover, &:active, &:visited {
      color: $main-color-clear;
    }
  }
  .go-to-next {
    @extend .accent-color-button;
    font-weight: 700;
    width: 27rem;
    height: 5rem;
    line-height: 5rem;
    margin: 5rem 0 10rem;
  }
  .request-more
  , .edit {
    background: $base-color;
    font-size: 1.4rem;
    font-weight: 700;
    height: 5rem;
    width: 15.8rem;
    line-height: 5rem;
    border: none;
    position: absolute;
    top: 5rem;
    right: 0;
    &:hover, &:active {
      background: tint($base-color, 15%);
    }
  }
  .link-to-open-house-and-events,
  .back-to-home,
  .back-to-contact-step1 {
    @include size(27rem, 5rem);
    @extend .accent-color-button;
    display: inline-block;
    line-height: 5rem;
    margin: 8.4rem 6.4rem;
  }
  .back-to-companies-search {
    @include size(27rem, 5rem);
    @extend .accent-color-button;
    display: inline-block;
    line-height: 5rem;
    margin: 4rem 0 6rem;
  }
}


/* document list
----------------------------------------------*/

#page-contact-step1 {
  #requested-document-header {
    font-weight: 400;
  }
  #steps + .contact-actions {
    .go-to-next {
      margin: 0rem 0 4rem;
    }
    .request-more {
      top: 0;
    }
  }
}


/* confirm
----------------------------------------------*/

#page-contact-step3 {
  h2 {
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 2.5rem;
    letter-spacing: 0.1rem;
    margin-bottom: 1rem;
  }
  #requested-documents-header {
    .total {
      float: right;
    }
  }
}

#page-general-contact-confirm,
#page-contact-step3 {
  *[disabled] {
    color: $main-color-clear;
  }
}


/* no request
----------------------------------------------*/

#no-request {
  font-weight: 500;
  letter-spacing: 0.1rem;
  text-align: center;
  h2 {
    font-size: 2rem;
  }
  p {
    font-size: 1.6rem;
  }
}
