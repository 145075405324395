/* favorite
----------------------------------------------*/

*[id^='page-favorite-past-works'],
*[id^='page-favorite-experts'] {
  #no-favorites {
    font-size: 1.3rem;
    h2 {
      text-align: center;
      border-top: 1px solid $border-color-demilight;
      border-bottom: 1px solid $border-color-demilight;
      padding: 1.25em 0;
      letter-spacing: 0.025em;
    }
    h3 {
      letter-spacing: 0.1em;
      font-weight: 500;
      margin: 1rem 0 1.7rem;
    }
  }
  #for-adding-favorites {
    font-weight: 500;
    letter-spacing: 0.1em;
    img {
      max-width: 38rem;
      display: block;
      margin: 5.7rem auto;
    }
  }
  .action-to-search {
    margin: 3.5rem auto;
    text-align: center;
  }
}

#page-favorite-past-works,
#page-favorite-experts {
  #no-favorites {
    margin-bottom: 10rem;
    h2 {
      font-size: 1.6rem;
    }
    h3 {
      font-size: 2rem;
    }
  }
  #for-adding-favorites {
    font-size: 1.6rem;
  }
  .how-to-list {
    padding: 1.25rem;
    li {
      margin: 0.75rem;
      width: calc(50% - 1.5rem);
    }
    .image {
      img {
        @include size(100%, auto);
      }
    }
  }
}

#page-favorite-past-works-mobile,
#page-favorite-experts-mobile {
  #no-favorites {
    padding: 4rem 1rem;
    h2 {
      font-size: 1.4rem;
    }
    h3 {
      font-size: 1.5rem;
    }
  }
  #for-adding-favorites {
    font-size: 1.4rem;
    img {
      @include size(auto, 17rem);
    }
  }
  .how-to-list {
    padding: 0.5rem;
    li {
      margin: 0.5rem;
      width: calc(100% - 1rem);
    }
    .image {
      img {
        @include size(100%, auto);
      }
    }
  }
}

/* favorite-past-works
----------------------------------------------*/

#page-favorite-past-works {
  #past-works-list {
    @extend .cropped-photo-medium;
  }
}
