/* colors
----------------------------------------------*/

$accent-color: #FAD00C;
$sub-color: lightcoral;
$base-color: #F3F0E4;
$main-color: #3B3B3B;
$main-color-clear: #000;
$highlight-color: #CF0000;
$highlight-color-light: #FF6F6F;
$ai-color: #FA830D;

$site-bg: white;
$default-font-color: #333;

$border-color: #B9B9B9;
$border-color-light: #E3E3E3;
$border-color-demilight: #BABABA;
$border-color-strong: #707070;


/* size
----------------------------------------------*/

$default-font-size: 10px;
$fixed-header-height-mobile: 8rem;
$gutter: 20px; // for Refills
$max-width: 1100px;
$small-container: 945px;
$profile-image-size: 172px;
$profile-image-size-mobile: 9.6rem;
$plus-size: 5rem;
$cover-height: 37rem;


/* position
----------------------------------------------*/

$modal-cancel-top: 14.7rem;


/* for calculation
----------------------------------------------*/

$sqrt2: 1.41;
