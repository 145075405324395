/* about
----------------------------------------------*/

#about {
  @extend .small-container;
  font-size: 1.6rem;
  padding-bottom: 10rem;
  section {
    h1 {
      font-size: 2.2rem;
      font-weight: 500;
      letter-spacing: 0.1em;
      margin: 10rem 0 5rem;
    }
  }
  #about-iloie {
    div:not(:last-child) {
      margin-bottom: 7rem;
    }
    p {
      line-height: 2;
      letter-spacing: 0.05em;
      margin-bottom: 4rem;
    }
    p:last-child {
      margin-bottom: 0;
    }
  }
  #local-menu {
    text-align: center;
    font-size: 1.5rem;
    background: $base-color;
    li {
      display: inline-block;
    }
    a {
      display: block;
      position: relative;
      line-height: 1;
      padding: 1.7rem 2.5rem 1.7rem 1.7rem;
      letter-spacing: 0.1em;
      &::before {
        @include with-arrow(down, black);
        @include arrow-position(top, left, 1.7em);
        position: absolute;
        font-size: 0.5em;
      }
    }
  }
}

#about-replan,
#about-web-magazine,
#about-company {
  p {
    line-height: 2;
    letter-spacing: 0.05em;
    margin-bottom: 2em;
  }
  p:last-child {
    margin-bottom: 0;
  }
  tr:last-child th,
  tr:last-child td
  {
    padding-bottom: 0;
  }
}

#about-company {
  table {
    th {
      padding: 0 2em 2em 0;
      text-align: left;
      letter-spacing: 0.1em;
      line-height: 1.87;
    }
    td {
      border-left: 1px solid $border-color-demilight;
      padding: 0 0 2em 2em;
      letter-spacing: 0.05em;
      line-height: 1.87;
    }
  }
}

.open-map {
  padding-left: 2.5em;
  position: relative;
  &::before {
    @include with-triangle(right, black);
    position: relative;
    left: -0.5em;
    top: 0.02em;
  }
}

#about-replan,
#about-web-magazine {
  .inner {
    display: flex;
  }
  .replan-image,
  .web-magazine-logo {
    padding-right: 6rem;
    img {
      @include size(25rem, auto);
      max-width: initial;
    }
  }
  .replan-text,
  .web-magazine-text {
    flex-grow: 1;
  }
  a:link, a:visited {
    color: $highlight-color-light;
  }
  a:hover, a:active {
    color: tint($highlight-color-light, 20%);
  }
  .link-to-back-numbers,
  .link-to-web-magazine {
    a {
      text-align: center;
      font-size: 1.5rem;
      letter-spacing: 0;
      height: 5rem;
      line-height: 5rem;
      display: block;
      &:link, &:visited {
        border: 1px solid $border-color-strong;
        color: $main-color-clear;
      }
      &:hover, &:active {
        border: 1px solid tint($border-color-strong, 20%);
        color: tint($main-color-clear, 30%);
      }
    }
  }
}

#terms {
  letter-spacing: 0.05em;
  font-weight: 500;
  h2 {
    @extend .simple-header;
  }
  p {
    line-height: 2;
    margin: 1.6rem 0;
  }
  ul {
    @extend .default-ul;
    li {
      line-height: 2;
    }
  }
}
