/* devices
----------------------------------------------*/

/*--- smartphone ---*/

$smaller-smartphone: (
  columns: 8,
  media: "(max-width: 375px)"
);

$larger-smartphone: (
  columns: 8,
  media: "(min-width: 376px)"
);

/*--- mini-tablet ---*/

$smaller-mini-tablet: (
  columns: 8,
  media: "(max-width: 768px)"
);

$larger-mini-tablet: (
  columns: 12,
  media: "(min-width: 769px)"
);

/*--- tablet ---*/

$smaller-tablet: (
  columns: 8,
  media: "(max-width: 980px)"
);

$larger-tablet: (
  columns: 12,
  media: "(min-width: 981px)"
);


/*--- max-width ---*/

$smaller-max-width: (
  columns: 12,
  media: "(max-width: #{$max-width})"
);

$larger-max-width: (
  columns: 12,
  media: "(min-width: #{$max-width + 1px})"
);

/*--- desktop ---*/

$smaller-desktop: (
  columns: 12,
  media: "(desk-top: 1231px)"
);

$larger-desktop: (
  columns: 12,
  media: "(min-width: 1232px)"
);

/*--- show or hide ---*/

.only-large-device {
  @include grid-media($larger-tablet) {
    visibility: show;
  }
  @include grid-media($smaller-tablet) {
    visibility: hidden;
  }
}

.only-small-device {
  @include grid-media($larger-tablet) {
    visibility: hidden;
  }
  @include grid-media($smaller-tablet) {
    visibility: show;
  }
}
