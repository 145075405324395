/* past-works
----------------------------------------------*/

#page-past-works,
#page-past-works-search {
  #page-header {
    h1::before {
      left: 40rem;
    }
  }
  #past-works-list {
    h1 {
      padding: 1rem 0;
      margin-bottom: 4rem;
      font-size: 1.5rem;
      font-weight: 400;
      letter-spacing: 0.2rem;
    }
  }
}

#past-works-container {
  @extend .small-container;
  .place-and-date {
    color: #606060;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.3;
    margin-bottom: 0.6rem;
  }
  #past-works-header {
    @include clearfix;
    h2 {
      font-size: 2.6rem;
      font-weight: 500;
      line-height: 1;
      margin-bottom: 1.8rem;
      p {
        margin: 0;
        font-size: inherit;
        line-height: inherit;
      }
    }
  }
  .company {
    font-size: 1.6rem;
    font-weight: 500;
    float: left;
    margin-bottom: 4.4rem;
  }
  #user-actions, .photo-favorite {
    @extend .user-actions-base;
    float: right;
    a, button, .button {
      height: 3.4rem;
      line-height: 3.4rem;
    }
  }
  p {
    margin: 2.4rem 0;
    font-size: 1.6rem;
    line-height: 2;
    letter-spacing: 0.1rem;
  }
  .photo-container {
    margin: 4.4rem 0 0 0;
    text-align: center;
  }
  .photo-caption {
    font-size: 1.4rem;
    text-align: left;
    line-height: 2;
    letter-spacing: 0.2rem;
  }
  .photo-container-half {
    display: inline-block;
    box-sizing: content;
      width: calc(50% - 0.5rem);
    &:nth-child(even) {
      margin: 0 0 0 0.5rem;
    }
    &:nth-child(odd) {
      margin: 0 0.5rem 0 0;
    }
    &:nth-child(n + 3) {
      margin-top: 4rem;
    }
    &:nth-child(2n + 3):last-child {
      margin: 4rem  auto 0;
    }
    &:only-child {
      margin: 0 auto;
    }
  }
  .link-to-details
  , .back-to-index {
    text-align: center;
    margin: 4.7rem 0;
    a {
      @extend .accent-color-button;
      @include size(27rem, 5rem);
      line-height: 5rem;
      display: block;
      margin: 0 auto;
    }
  }
}


/* past-works-index
----------------------------------------------*/

#page-past-works {
  #past-works-list {
    @extend .cropped-photo-large;
  }
}


/* past-works-search
----------------------------------------------*/

#page-past-works-search {
  .search-form {
    @include clearfix;
    border: 1px solid black;
    padding: 1.2rem;
    font-size: 1.3rem;
    label[for='past_work_search_form_categories_renovation_condo'] {
      letter-spacing: -0.1rem;
    }
  }
  #past-works-list {
    @extend .cropped-photo-medium;
  }
}
