/* ad and recommended experts
----------------------------------------------*/

.ad {
  margin: 0 0 4rem 0;
}

#recommended-experts-short {
  h2 {
    background: url('../images/recommended-experts.svg') 4rem top no-repeat;
    padding: 3rem 0 0 14rem;
    height: 7.7rem;
    margin: 0;
    font-size: 1.4rem;
    font-weight: 700;
  }
  li {
    padding: 2rem 2rem 2rem 0;
    border-bottom: 1px solid $border-color-demilight;
    & > a {
      display: flex;
    }
  }
  .photo {
    @extend .cropped-photo-sidebar;
    flex-basis: 11rem;
  }
  h3 {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 2em;
    height: 4em;
    margin: 0;
    flex-grow: 1;
    flex-basis: calc(100% - 11rem);
  }
}
