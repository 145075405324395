// 公開側用 CSS エンドポイント
@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'bourbon';
@import 'bourbon-neat';

$fa-font-path: '~@fortawesome/fontawesome-free/webfonts';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';

$slick-font-path: '~slick-carousel/slick/fonts/';
$slick-loader-path: '~slick-carousel/slick/';
@import '~slick-carousel/slick/slick';
@import '~slick-carousel/slick/slick-theme';
@import url(//fonts.googleapis.com/earlyaccess/notosansjapanese.css);

@import 'stylesheets/basic-settings';
@import 'stylesheets/button';
@import 'stylesheets/devices';
@import 'stylesheets/large-devices';
@import 'stylesheets/modal';
@import 'stylesheets/reset';
@import 'stylesheets/with-arrow';
@import 'stylesheets/with-triangle';
@import 'stylesheets/slick-override';
@import 'stylesheets/tab';

@import 'stylesheets/common';
@import 'stylesheets/headers';
@import 'stylesheets/search-form';

@import 'stylesheets/ad-and-recommended-experts';
@import 'stylesheets/banners';
@import 'stylesheets/calendar';
@import 'stylesheets/guide-and-no-favorites';
@import 'stylesheets/news-slider';
@import 'stylesheets/pagination';
@import 'stylesheets/past-works-list';
@import 'stylesheets/results-header';
@import 'stylesheets/results-list';
@import 'stylesheets/site-bread-crumbs';
@import 'stylesheets/site-footer';
@import 'stylesheets/site-header';
@import 'stylesheets/contact-steps';

@import 'stylesheets/about';
@import 'stylesheets/companies';
@import 'stylesheets/contact';
@import 'stylesheets/favorite';
@import 'stylesheets/guide';
@import 'stylesheets/login';
@import 'stylesheets/open-house-and-events';
@import 'stylesheets/news';
@import 'stylesheets/past-works';
@import 'stylesheets/privacy-policy';
@import 'stylesheets/visitors';

@import 'stylesheets/iloie-component';
@import 'stylesheets/et-dev';
