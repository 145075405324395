/* guide
----------------------------------------------*/

#page-guide {
  #page-header {
    h1::before {
      left: 94rem;
    }
  }
}

#guide {
  @extend .small-container;
  font-size: 1.2rem;
  padding-bottom: 6.6rem;
  *[id^='case'] {
    border: 1px solid $border-color-strong;
    background-repeat: no-repeat;
    margin-bottom: 5.3rem;
  }
  h2 {
    margin: 8rem 0 0 0;
    padding-left: 8.5rem;
    height: 10rem;
    position: relative;
    font-size: 2rem;
    font-weight: 500;
    letter-spacing: 0.1em;
    line-height: 1.5;
    span {
      text-transform: uppercase;
      font-size: 1.4rem;
      background: $accent-color;
      position: absolute;
      padding: 0.5rem 1.5rem;
      line-height: 1;
      top: 0;
      left: -2rem;
      letter-spacing: 0;
      &::after {
        @include with-triangle(right, $accent-color);
        border-width: 1.2rem 1rem;
        top: 0;
        right: -2rem;
      }
    }
  }
  #case0 {
    background: {
      image: url('../images/guide-case1.svg');
      position: right 7rem top 5.7rem;
      size: 34.8rem auto;
    }
  }
  #case1 {
    background: {
      image: url('../images/dreaming.svg');
      position: right 7rem top 5.7rem;
      size: 37.8rem auto;
    }
  }
  #case2 {
    background: {
      image: url('../images/guide-case2.svg');
      position: right 10.2rem top 4rem;
      size: 32rem auto;
    }
  }
  #case3 {
    background: {
      image: url('../images/guide-case3.svg');
      position: right 8.1rem top 4.5rem;
      size: 38rem auto;
    }
  }
  #case4 {
    background: {
      image: url('../images/guide-case4.svg');
      position: right 7.8rem top 3.4rem;
      size: 38rem auto;
    }
  }
  #case5 {
    background: {
      image: url('../images/guide-clipped.svg');
      position: right 7.8rem top 9.4rem;
      size: 38rem auto;
    }
  }
  .action-from-guide {
    margin: 0 0 8rem 6.2rem;
  }
  .how-to-list {
    li {
      margin: 2rem;
      width: 26rem;
    }
    .image {
      img {
        @include size(26rem, auto);
      }
    }
  }
}
