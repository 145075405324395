/* guide and no favorites
----------------------------------------------*/

.action-to-search,
.action-from-guide {
  font-size: 1.4rem;
  font-weight: 700;
  a {
    @extend .accent-color-button;
    @include size(32.5rem, 6.8rem);
    display: inline-block;
    text-align: center;
    line-height: 6.8rem;
    position: relative;
    &::after {
      @include with-triangle(right, $accent-color);
      border-width: 3.4rem 1.7rem;
      top: 0;
      right: -3.4rem;
    }
    &:hover, &:active {
      &::after {
        border-left: 1.7rem solid tint($accent-color, 10%);
      }
    }
  }
}

#case0, #case5 {
  .action-from-guide {
    a {
      background: $ai-color;
      color: white;
      font-weight: 500;
      &::after {
        border-left-color: $ai-color;
      }
    }
  }
}

.how-to-list {
  background: $base-color;
  text-align: center;
  padding-bottom: 1.8rem;
  li {
    display: inline-block;
    vertical-align: top;
  }
  .description {
    text-align: left;
    display: flex;
    align-items: center;
    margin: 1.6rem 0;
    height: 3em;
  }
  .number {
    flex-basis: 2.5rem;
    min-width: 2.5rem;
    height: 2.5rem;
    line-height: 2.5rem;
    background: $accent-color;
    text-align: center;
    margin: 0 1rem 0 0;
    font-size: 1.4rem;
    font-weight: 700;
    vertical-align: middle;
  }
}
