/* calendar
----------------------------------------------*/

.ui-datepicker-trigger{
  border:none;
  background:none;
  font-size: 2.2rem;
  line-height: 3rem;
  height: 3rem;
}

#ui-datepicker-div {
  @include size(31.6rem, auto);
  @include clearfix;
  background: $base-color;
  font-weight: 500;
  z-index: 52 !important; // dropzone より高く
}

.ui-datepicker-header {
  position: relative;
  line-height: 2rem;
  height: 2rem;
  margin: 1rem;
}

.ui-datepicker-title {
  text-align: center;
  font-size: 1.8rem;
  position: absolute;
  top: 0;
  left: calc(50% - 12rem / 2);
  width: 12rem;
  text-align: center;
}

.ui-datepicker-calendar {
  @include size(29.6rem, 100%);
  background: white;
  font-size: 1.4rem;
  margin: 1rem;
  thead {
    padding: 1rem;
    margin: 1rem;
  }
  tr {
    padding: 1rem;
    margin: 1rem;
  }
  th {
    span {
      display: block;
      padding: 1rem;
      border-bottom: 1px solid $border-color-strong;
    }
  }
  td {
    text-align: center;
    padding: 0.2rem;
  }
  th, td {
    &:first-child  {
      padding-left: 1rem;
    }
    &:last-child {
      padding-right: 1rem;
    }
  }
  tr:last-child {
    th, td {
      padding-bottom: 1rem;
    }
  }
}

.ui-datepicker-prev,
.ui-datepicker-next {
  position: absolute;
}

.ui-datepicker-prev {
  left: 6rem;
}

.ui-datepicker-next {
  right: 6rem;
}

.ui-icon {
  text-indent: -9999px;
  display: inline-block;
  position: relative;
  width: 100%;
  @include size(2rem, 2rem);
  &::before {
    font-size: 1.4rem;
    top: 0.5rem;
  }
}

.ui-datepicker-prev {
  .ui-icon {
    &::before {
      @include with-arrow(left, $border-color-strong);
      position: absolute;
      left: 0.5em;
    }
  }
}

.ui-datepicker-next {
  .ui-icon {
    &::before {
      @include with-arrow(right, $border-color-strong);
      position: absolute;
      right: 0.5em;
    }
  }
}

.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight {
  display: block;
  background: rgba($accent-color, 0.5);
}

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
a.ui-button:active, .ui-button:active,
.ui-button.ui-state-active:hover {
  display: block;
  background: $accent-color;
}

.date-selector {
  .from-to {
    padding: 0 0.5rem;
  }
}
