/* contact steps
----------------------------------------------*/

#steps {
  margin-bottom: 6rem;
  font-size: 1.4rem;
  font-weight: 700;
  text-align: center;
  .number {
    @include size(2.3rem, 2.3rem);
    display: inline-block;
    background: white;
    border-radius: 50%;
    line-height: 2.3rem;
    position: absolute;
    left: 1em;
    top: calc((4.4rem - 2.3rem) / 2);
  }
  li {
    display: inline-block;
    line-height: 4.4rem;
    position: relative;
    padding: 0 0 0 2rem;
    &:not(:first-child) {
      margin-left: 0.5rem + 2rem;
    }
    &:not(:last-child) {
      @include size(21rem, 4.4rem);
      &::after {
        @include size(2rem, 4.4rem);
        content: "";
        width: 0;
        height: 0;
        display: inline-block;
        position: absolute;
        border-top: 2.2rem solid transparent;
        border-bottom: 2.2rem solid transparent;
        right: -2rem;
      }
    }
    &:last-child {
      @include size(23rem, 4.4rem);
    }
  }
  .progressed {
    background: $accent-color;
    &:not(:last-child) {
      &::after {
        border-left: 2rem solid $accent-color;
      }
    }
  }
  .not-yet {
    background: #F0F0F0;
    &:not(:last-child) {
      &::after {
        border-left: 2rem solid #F0F0F0;
      }
    }
  }
}
