/*    FOR DEVELOPMENT
/*
/*    【使い方】
/*
/*
/*    ※ このCSSは最後に読み込んでください
/*
/*    ※ <div class="et-dev"></div> の中に書くと適用されます
/*
----------------------------------------------*/

.et-dev {
  width: 1100px;
  margin: 20px auto;
  padding: 20px;
  background: #efefef;

  * {
    color: black;
    box-sizing: border-box;
    font-size: 16px;
  }

  html, body, div, span, p, a, del, em, img, ol, ul, li, fieldset, form, label, table, tr, th, td {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
  }

  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section, div {
    display: block;
  }

  html, body {
  }

  body {
  }

  div, p {
    display: block;
    margin: 10px 0;
    line-height: 1.5;
  }

  ol, ul, dl {
    margin: 10px 0;
  }

  ol, ul {
    padding: 0 0 0 20px;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  dd {
    margin-left: 2em;
  }

  img {
    max-width: 100%;
    height: auto;
    width: auto;
  }

  hr {
    border: none;
    border-top: 1px solid black;
    margin: 20px 0;
  }

  /* link ---------------------------------*/

  a {
    text-decoration: underline;
    &:link, &:visited {
      color: #3A80A3;
    }
    &:hover, &:active {
      color: tint(#3A80A3, 10%);
    }
  }

  /* header ---------------------------------*/

  h1, h2, h3, h4, h5, h6 {
    font-weight: bold;
    margin: 40px 0;
  }

  h1 {
    font-size: 20px;
  }

  h2 {
    font-size: 19px;
  }

  h3 {
    font-size: 18px;
  }

  h4 {
    font-size: 17px;
  }


  /* form ---------------------------------*/

  form * {
    font-size: 16px;
  }

  input[type='text']
  , input[type='search']
  , input[type='password']
  , input[type='email']
  , input[type='url']
  , input[type='datetime']
  , input[type='datetime-local']
  , input[type='date']
  , input[type='month']
  , input[type='week']
  , input[type='time']
  , input[type='tel']
  {
    background: white;
    border: 1px solid #999;
    padding: 1em;
    height: 50px;
    line-height: 50px;
  }

  input[type='color']
  {
    border: 1px solid #999;
    padding: 0;
    height: 50px;
    line-height: 50px;
  }

  input[type='file'] {
    height: 50px;
  }

  input[type='checkbox'],
  input[type='radio'] {
    display: inline-block;
    width: 1em;
    height: 1em;
  }

  input[type='radio'] {
    -webkit-appearance: radio;
    -moz-appearance: radio;
    appearance: radio;
  }

  input[type='checkbox'] {
    -webkit-appearance: checkbox;
    -moz-appearance: checkbox;
    appearance: checkbox;
  }

  button
  , input[type='button']
  , input[type='submit']
  , input[type='reset']
  {
    border: none;
    border-radius:  4px;
    padding: 0 20px;
    background: black;
    color: white;
    height: 5rem;
    line-height: 5rem;
  }

  select {
    border: 1px solid #999;
    border: auto;
    -webkit-appearance: menulist;
    -moz-appearance: menulist;
    appearance: menulist;
  }

  /* table ---------------------------------*/

  table {
    margin: 1rem 0;
  }

  table, tr, th, td  {
    border: 1px solid black;
    border-collapse: collapse;
  }

  th, td {
    padding: 2rem;
  }

}
