/* news-slider
----------------------------------------------*/

#page-home #open-house-and-events,
#page-company #news-and-open-house,
#page-open-house-and-events-details #other-events
{
  .photo-and-summary,
  .photo-and-header {
    @include clearfix;
  }
  .photo {
    width: 12rem;
    float: left;

    line-height: 1;
    @extend .cropped-photo-small;
    position: relative;
  }
  .other-event-header, 
  .summary {
    float: right;
    width: calc(100% - 12rem);

    line-height: 1;
    word-break: break-all;
  }
  .category {
    padding: 0.3rem 0.5rem;
    font-size: 1.2rem;
    margin: 0 0 1rem 0;
    display: inline-block;
  }
  .event-name {
    font-size: 1.5rem;
    line-height: 1.5;
    letter-spacing: 0.05em;
  }
  .booking {
    background: #696969;
    color: white;
    line-height: 1.8rem;
    font-size: 1.1rem;
    text-align: center;
    width: 10rem;
    position: relative;
    bottom: 0.1rem;
  }
  & > ul {
    & > li {
      vertical-align: top;
      a {
        @include clearfix;
        display: block;
      }
    }
  }
  .details {
    clear: both;
    padding: 1.4rem 0 0.6rem 0;
    li {
      margin: 0 0 0.6rem 0;
      display: block;
      font-size: 1.4rem;
      letter-spacing: 0.025em;
      line-height: 1.9;
    }
  }
}

#page-company #news-and-open-house,
#page-company #news-and-open-house #more-news-and-open-houses,
#page-open-house-and-events-details #other-events,
{
  & > ul {
    padding: 3.5rem 0 0;
    font-size: 1.4rem;
    display: flex;
    flex-wrap: wrap;
    & > li {
      flex-basis: 50%;
      border-bottom: 1px solid $border-color-demilight;
      &:nth-child(odd):not(:only-child) {
        border-right: 1px solid $border-color-demilight;
        padding-right: 3rem;
      }
      &:nth-child(even) {
        padding-left: 3rem;
      }
    }
    & > li:last-child,
    & > li:nth-child(odd):nth-last-child(-n+2) {
      border-bottom: none;
    }
    & > li:nth-child(n+3) {
      padding-top: 3rem;
    }
    & > li:nth-child(2) {
      border-right: none;
    }
  }
}

#page-home #open-house-and-events {
  .slider {
    margin: 0 auto 8.3rem;
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
  }
  .slick-slide {
    & > div {
      display: flex !important;
      flex-basis: 50%;
      &:nth-child(1) {
        .content {
          padding-top: 1rem;
          border-bottom: 1px solid $border-color;
        }
      }
      &:nth-child(2) {
        .content {
          padding-top: 3rem;
        }
      }
    }
  }
  .content {
    vertical-align: top;
    &:nth-child(1) {
      padding-right: 6rem;
      padding-left: 1rem;
      border-right: 1px solid $border-color;
    }
    &:nth-child(2) {
      padding-right: 1rem;
      padding-left: 6rem;
    }
  }
  .company {
    font-size: 1.2rem;
  }
  .prefecture {
    font-weight: 500;
  }
  & > ul {
    padding: 1.4rem 4rem;
  }
}

#page-company #news-and-open-house {
  & > ul {
    padding: 0 5rem;
  }
}

.slider {
  .content {
    overflow: hidden;
    height: 20rem;
  }
  .details {
    li {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
