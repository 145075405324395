/* news
----------------------------------------------*/

#page-news {
  #news-list {
    li {
      font-size: 1.4rem;
      border-bottom: 1px solid $border-color-demilight;
      letter-spacing: 0.2rem;
      @include grid-media($larger-mini-tablet) {
        margin-bottom: 2rem;
      }
      @include grid-media($smaller-mini-tablet) {
        padding: 3rem 0 1rem;
      }
    }
  }
  .news-category {
    font-size: 1.3rem;
    margin-bottom: 2rem;
    .new {
      margin-right: 1em;
    }
  }
  .published-date {
    color: #6C6C6C;
    margin: 2rem 0;
  }
  #site-sidebar {
    h2 {
      font-size: 1.8rem;
      font-weight: 500;
      letter-spacing: 0.05em;
      padding: 1rem;
      border-bottom: 1px solid $border-color-demilight;
      text-align: center;
      margin: 2rem 0;
    }
    .news-categories-list {
      letter-spacing: 0.1em;
      li {
        margin-bottom: 1em;
        a {
          padding-left: 1.5em;
          position: relative;
          &::before {
            @include with-arrow(right, $main-color-clear);
            @include arrow-position(top, left, calc((1.4rem * 1.5 - 1.4rem) / 2), 0em);
            position: absolute;
          }
        }
      }
    }
  }
  #month-selector {
    position: relative;
    border: 1px solid $border-color-strong;
    &::before {
      @include with-arrow(down, $main-color-clear);
      @include arrow-position(top, right, calc((4.2rem - 1.4rem) / 2), 1em);
      position: absolute;
      pointer-events: none;
    }
    select {
      border: none;
      width: 100%;
      background: white;
      height: 4.2rem;
      line-height: 4.2rem;
      padding: 0 1em;
    }
  }
}
