/* open-house-and-events
----------------------------------------------*/

body[id^='page-open-house-and-events'] {
  #page-header {
    h1::before {
      left: 64rem;
    }
  }
  #results-list .category,
  #open-house-and-events-details > #site-main-container > .category
  {
    @include size(18rem, 3.6rem);
    line-height: 3.6rem;
    font-size: 1.4rem;
  }
  .company {
    font-weight: 500;
    border-bottom: 1px solid $border-color-demilight;
    padding: 1em 0;
    letter-spacing: 0.1rem;
    margin-bottom: 1rem;
  }
  .summary {
    dl {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      letter-spacing: 0.1rem;
    }
    dt {
      flex-basis: 7em;
      align-items: stretch;
    }
    dd {
      flex-basis: calc(100% - 7em);
      align-items: stretch;
      margin: 0;
      position: relative;
      &::before {
        content: ":";
        display: inline-block;
        width: 1em;
        text-align: center;
        position: absolute;
        left: -1.5em;
      }
    }
  }
}

/* search(index)
----------------------------------------------*/

#page-open-house-and-events-search {
  .search-form {
    .search-by-keyword {
      width: 100%;
    }
  }
  #results-list {
    li {
      @include clearfix;
      border: 1px solid $border-color;
      clear: both;
      margin: 0 0 2rem 0;
      position: relative;
      padding: 2rem;
    }
    .category {
      position: absolute;
      top: 0;
      left: 0;
    }
    h3 {
      font-size: 2rem;
      font-weight: 500;
      line-height: 1.5;
      padding: 2rem 0;
      margin: 2rem 0;
      letter-spacing: 0.2rem;
      word-break: break-all;
    }
    .summary {
      width: calc(100% - 251px - 2rem);
      float: right;
      font-size: 1.4rem;
      dd {
        margin-bottom: 1rem;
      }
    }
    .link-to-details {
      @extend .accent-color-button;
      position: absolute;
      right: 2rem;
      bottom: 2rem;
      padding: 0.8rem 2rem;
      clear: both;
    }
    .photo {
      @extend .cropped-photo-results;
      float: left;
      margin: 0 2rem 2rem 0;
      width: 251px;
    }
  }
}


/* details(show)
----------------------------------------------*/

#open-house-and-events-details {
  #site-main-container > .category {
    float: left;
  }
  .published-date {
    float: right;
    font-size: 1.6rem;
    font-weight: 500;
    color: #A0A0A0;
    line-height: 3.6rem;
  }
  #open-house-and-events-header {
    font-size: 2.2rem;
    font-weight: 500;
    line-height: 1;
    clear: both;
    padding: 3.2rem 0 2rem;
  }
  .company {
    font-size: 1.6rem;
  }
  #site-main-container .points h3 {
    margin-top: 0; // override
  }
  .points {
    display: flex;
    margin-bottom: 1.5rem;
    font-size: 1.6rem;
    letter-spacing: 0.1em;
    h3 {
      white-space: nowrap;
      span {
        display: block;
        text-align: center;
        background: $base-color;
        font-weight: 500;
        line-height: 3rem;
        padding: 0.7rem 1.2rem;
      }
    }
    li {
      display: inline-block;
      line-height: 3rem;
      padding: 0.7rem 1.2rem;
    }
  }
  .summary {
    font-size: 1.6rem;
    letter-spacing: 0.1em;
    dd {
      margin-bottom: 0.6rem;
    }
  }
  #description {
    font-size: 1.6rem;
    line-height: 2;
    letter-spacing: 0.05em;
    margin: 0 0 10rem 0;
    p:not(:last-child) {
      margin: 0 0 1em 0;
    }
    .photo {
      margin: 5rem 0 6rem;
      img {
        width: 100%;
        max-height: 49.6rem;
        object-fit: contain;
      }
    }
  }
  .details {
    h3 {
      @extend .details-header;
    }
    .map {
      h3 {
        border-bottom: none;
      }
    }
    table {
      @extend .details-table;
    }
    th {
      white-space: nowrap;
    }
    .booking {
      a {
        &:link, &:visited {
          color: #CF0000;
        }
        &:hover, &:active {
          color: tint(#CF0000, 20%);
        }
      }
    }
    .period-notes {
      font-size: 1.4rem;
      margin-top: 0.5em;
    }
  }
  #site-main-container {
    h3 {
      font-size: 1.5rem;
      font-weight: 500;
      line-height: 3;
      margin-top: 3rem;
      letter-spacing: 0.1rem;
    }
  }
  #map-canvas {
    margin-bottom: 2rem;
  }
  #other-events {
    h3 {
      border-bottom: 1px solid $border-color-demilight;
    }
  }
  #link-to-company,
  #back-to-index {
    text-align: center;
    margin: 4.7rem 0;
    a {
      @extend .accent-color-button;
      @include size(27rem, 5rem);
      line-height: 5rem;
      display: block;
      margin: 0 auto;
    }
  }
}
