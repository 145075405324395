/* accordion tabs
----------------------------------------------*/

@import 'with-triangle'; // FIXME ここで読み込まないとUndefinedになる

.accordion-tabs {
  $base-border-color: #B9B9B9 !default;
  $base-background-color: #F0F0F0 !default;
  $base-spacing: 1.5rem !default;
  $action-color: black !default;
  $tab-content-background: white;
  $tab-active-background: $tab-content-background;
  $tab-inactive-color: $base-background-color;
  $tab-inactive-hover-color: tint($base-background-color, 20%);

  @include clearfix;
  line-height: 1.5;
  padding: 0;

  .tab-header-and-content {
    list-style: none;
    display: inline;
    & > *:first-child {
      margin-right: -0.5rem;
      // ↑ タブ2つが兄弟ではないため、50%幅で並べるとコーディングで改行のスペースが生じるのを避けられない。そのスペース分をずらして段落ちを防いでいる。
    }
  }

  .tab-link {
    display: block;
    text-decoration: none;
    display: inline-block;
    border-bottom: 1px solid #9F9F9F;
    width: 50%;
    &:link, &:hover, &:active, &:visited {
      color: #909090;
    }
    &:focus {
      outline: none;
    }
    &.is-active {
      margin-bottom: -1px;
      color: black;
      position: relative;
      &::before {
        @include with-arrow(up, #9F9F9F);
        position: absolute;
        bottom: -1.0rem;
        left: calc(50% - 1.0rem);
        pointer-events: none;
        font-size: 2.6rem;
      }
      &::after {
        @include with-triangle(up, white);
        position: absolute;
        bottom: -0.3rem;
        left: calc(50% - 1.4rem);
        transform: none;
        border-width: 0 1.3rem 1.3rem 1.3rem;
      }
    }
  }

  .tab-content {
    background: $tab-content-background;
    display: none;
    padding: 1.5rem;
    width: 100%;
    float: left;
  }
}
