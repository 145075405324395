/* privacy policy
----------------------------------------------*/

#privacy-policy {
  @extend .small-container;
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: 0.05em;
  padding-bottom: 10rem;
  p {
    line-height: 2;
    margin-bottom: 2em;
  }
  p:last-child {
    margin-bottom: 0;
  }
  h2 {
    @extend .simple-header;
  }
  ul {
    @extend .default-ul;
    li {
      line-height: 2;
    }
  }
}
