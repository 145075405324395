/* with-arrow
----------------------------------------------*/

$diagonal: 1em;
$side-width: calc($diagonal / $sqrt2);
$space: $diagonal - $side-width;
$border-width: 1px;

@mixin with-arrow($direction: right, $color: black) {
  content: "";
  width: calc(1em / #{$sqrt2});
  height: calc(1em / #{$sqrt2});
  display: block;
  position: relative;
  border-top: $border-width solid $color;
  border-right: $border-width solid $color;
  @if $direction == 'right' {
    transform: rotate(45deg);
  }
  @if $direction == 'down' {
    transform: rotate(45deg * 3);
  }
  @if $direction == 'left' {
    transform: rotate(45deg * 5);
  }
  @if $direction == 'up' {
    transform: rotate(45deg * 7);
  }
}

@mixin arrow-position($vertical-start: top, $horizontal-start: left, $vertical-space: 0em, $horizontal-space: 0em) {
  @if $vertical-start == 'top' {
    top:  calc(#{$space} / 2 - #{calc($border-width / 2)} + #{$vertical-space});
  }
  @if $vertical-start == 'bottom' {
    bottom:  calc(#{$space} / 2 - #{calc($border-width / 2)} + #{$vertical-space});
  }
  @if $horizontal-start == 'left' {
    left:  calc(#{$space} / 2 - #{calc($border-width / 2)} + #{$horizontal-space});
  }
  @if $horizontal-start == 'right' {
    right:  calc(#{$space} / 2 - #{calc($border-width / 2)} + #{$horizontal-space});
  }
}
