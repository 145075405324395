/* banners
----------------------------------------------*/

#banners {
  background: $base-color;
  clear: both;
  .slider {
    margin: 0 auto;
    text-align: center;
    @include grid-media($larger-max-width) {
      padding: 4rem 0;
      //width: $max-width;
    }
    @include grid-media($smaller-max-width) {
      //width: 100%;
      padding: 2.3rem 0;
    }
    .slick-slide {
      padding: 0 1rem;
    }
  }
}
