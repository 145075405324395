/* search-form
----------------------------------------------*/

#more-options-wrapper {
  border-bottom: 1px dotted $border-color-demilight;
  margin: 2rem;
}

#more-options-header {
  font-size: 1.6rem;
  font-weight: 500;
  text-align: center;
  display: block;
  margin: 1rem 2rem;
  line-height: 1;
  img {
    @include size(auto, 1.7rem);
    vertical-align: top;
    margin: 0 1rem 0 0;
  }
}

#more-options-a,
#more-options-b {
  border-top: 1px solid $main-color-clear;
  .search-by-building-cost,
  .search-by-construction-methods,
  .search-by-service-categories
  {
    border-bottom: 1px dotted $border-color-demilight;
  }
}

*[id^='more-options-button-'] {
  display: none;
  & + label {
    &::after {
      @include with-arrow(down, $main-color-clear);
      display: block;
      margin: 1rem auto 2rem;
    }
    & + #more-options-a,
    & + #more-options-b {
      display: none;
    }
  }
  &:checked {
    & + label {
      &::after {
        @include with-arrow(up, $main-color-clear);
      }
      & + #more-options-a,
      & + #more-options-b {
        display: block;
      }
    }
  }
}

.search-by-area {
  padding: 0 0 2rem 0;
  & > * {
    display: inline-block;
  }
  select {
    width: 11rem;
    margin-left: 1rem;
  }
  .select-wrapper {
    margin: 0 0 0 2rem;
    &::before {
      position: absolute;
    }
  }
}

#page-home #find-experts-first-step,
#page-company #find-experts-second-step,
#open-house-and-events-search
{
  .search-by-categories {
    label {
      border: 0.1rem solid $main-color-clear;
      font-size: 1.4rem;
      line-height: 1.5;
      color: $main-color-clear;
      margin: 1.3rem 1.3rem 1.3rem 0;
      display: inline-block;
      cursor: pointer;
    }
    input {
      display: none;
      &:checked  + label {
        border: 0.3rem solid $accent-color;
        margin: -0.2rem;
      }
    }
  }
}

.search-button {
  @extend .accent-color-button;
  font-weight: 700;
}

.reset-button {
  background: #E8E8E8;
  font-size: 1.4rem;
  font-weight: 700;
  border: none;
  position: absolute;
  right: 0;
  &:hover, &:active {
    background: tint(#E8E8E8, 20%);
  }
}

.sort {
  width: 20em;
  margin: 3rem 0 0;
  select {
    width: 100%;
  }
  .select-wrapper {
    position: relative;
    &::before {
      @include with-triangle(down, black);
      position: absolute;
      top: calc(50% - 0.7em / 2);
      font-size: 1.2rem;
      right: 1rem;
      pointer-events: none;
      transform: none;
    }
  }
}

.search-actions {
  clear: both;
  position: relative;
  @include clearfix;
  margin-bottom: 4rem;
  .call {
    line-height: 5rem;
    float: left;
  }
  em {
    border-bottom: 2px solid black;
  }
}

#find-experts-second-step,
#page-past-works-search,
#page-open-house-and-events-search
{
  .search-button,
  .reset-button {
    line-height: 4.5rem;
    height: 4.5rem;
  }
  .search-button {
    width: 18.1rem;
  }
  .reset-button {
    padding: 0 2rem;
  }
}

.search-form input[type="search"]
, .search-form select
, .sort select {
  background: $base-color;
  border: none;
  border-left: 1px solid #979797;
}

#page-open-house-and-events-search #site-main-container,
#page-company #find-experts-second-step,
#site-sidebar {
  .search-form {
    font-size: 1.3rem;
    border: 1px solid $main-color-clear;
    .form-header {
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 1;
      padding: 0.8rem 0 0 0;
    }
  }
  .search-by-building-cost .inner,
  .search-by-keyword {
    padding: 2rem 0;
    display: flex;
    .form-header {
      padding-right: 1em;
    }
    input {
      width: 38.4rem;
    }
  }
}

#site-main-container {
  .search-form {
    @include clearfix;
    .search-by-building-cost,
    .search-by-construction-methods,
    .search-by-service-categories
    {
      padding-bottom: 1rem;
      .form-header {
        margin: 2rem 0;
      }
    }
    .search-by-construction-methods {
      label {
        display: inline-block;
        margin: 0;
        width: 33.33%;
      }
    }
    .search-by-service-categories {
      label {
        display: inline-block;
        margin: 0;
        width: 50%;
      }
    }
    .search-by-area {
      .select-wrapper-with-triangle {
        label {
          margin-left: 2.2rem;
        }
      }
    }
    .tab-content {
      padding: 0;
    }
    .tab-link {
      font-size: 1.4rem;
    }
  }
  .search-actions {
    margin: 2rem 0;
    text-align: center;
  }
}

#site-sidebar {
  *[class^="search-by"] {
    border-bottom: 1px dotted $border-color-demilight;
    padding-bottom: 2.2rem;
  }
  .search-form {
    .header {
      font-size: 1.6rem;
      font-weight: 500;
      border-bottom: 1px solid $main-color-clear;
      line-height: 1;
      padding: 0 0 1rem 0;
      img {
        @include size(auto, 1.7rem);
        vertical-align: top;
        margin: 0;
      }
    }
    .form-header {
      margin: 2rem 0;
    }
  }
  .search-by-area {
    .inner {
      display: flex;
      margin-bottom: 1rem;
    }
    label {
      flex-basis: 5em;
      line-height: 3rem;
    }
    .select-wrapper-with-triangle {
      flex-grow: 1;
    }
    select {
      width: 100%;
      margin: 0;
    }
  }
  .search-by-categories,
  .search-by-family-structure {
    @extend .default-checkbox;
    & > label {
      margin: 0 !important;
      display: inline-block;
    }
  }
  .search-by-categories {
    label {
      min-width: 40%;
    }
  }
  .search-by-family-structure {
    label {
      min-width: 50%;
    }
  }
  .search-by-price-range {
    .inner {
      display: flex;
    }
  }
  .search-button {
    margin: 0 2rem 0 0;
    width: calc(55% - 2rem);
    padding: 0 2rem;
    float: left;
  }
  .reset-button {
    width: 45%;
    padding: 0 2rem;
    float: right;
  }
  .search-actions {
    margin: 2.2rem 0 1.2rem;
    @include clearfix;
  }
}


#find-experts-first-step,
#find-experts-second-step {
  .tab-link {
    line-height: 1;
    padding: 2.7rem 0;
    text-align: center;
  }
}

#find-experts-first-step {
  text-align: center;
  font-size: 1.4rem;
  font-weight: 500;
  background: $base-color;
  .header {
    line-height: 5.5rem;
    height: 5.5rem;
    background: $accent-color;
    font-size: 2rem;
    padding: 0 2.8rem;
    position: relative;
    margin-bottom: calc(1.8rem + 1rem);
    &::after {
      @include with-triangle(down, $accent-color);
      position: absolute;
      bottom: -1.8rem;
      left: calc(50% - 1.8rem);
      pointer-events: none;
      transform: none;
      border-width: 1.8rem 1.8rem 0 1.8rem;
    }
  }
  .step-header {
    font-size: 1.8rem;
    text-align: left;
    margin-bottom: 1.4rem;
    .step {
      font-size: 1.4rem;
      margin-right: 1em;
    }
  }
  .search-by-area {
    margin-bottom: 2.8rem;
  }
  .search-by-area,
  .search-by-categories-wrapper {
    position: relative;
    background: white;
    &::after {
      @include with-triangle(down, white);
      position: absolute;
      bottom: -1.8rem;
      left: calc(50% - 1.8rem);
      pointer-events: none;
      transform: none;
      border-width: 1.8rem 1.8rem 0 1.8rem;
    }
  }
  .search-by-area,
  .search-by-keywords {
    padding: 3.6rem 0;
    background: white;
  }
  .select-wrapper-with-triangle {
    margin: 0 2.7rem;
    display: inline-block;
    select {
      height: 6.5rem;
      line-height: 6.5rem;
      width: 20rem;
    }
  }
  .search-by-categories-wrapper {
    background: white;
    @include clearfix;
  }
  .search-by-categories {
    @include clearfix;
    padding: 3.5rem 5.5rem;
    margin-bottom: 4.6rem;
    .categories {
      padding-top: 2.7rem;
      li {
        padding: 0;
      }
      label {
        padding: 0 0.5rem;
      }
    }
  }
  .search-by-keywords {
    display: flex;
    padding: 0;
    #company_search_form_keyword {
      flex: 1;
      height: 5rem;
      line-height: 5rem;
      background: white;
      font-size: 1.4rem;
    }
    .search-button {
      width: 13rem;
      background-position: calc(50% - 2.5em) 1.75rem; // value: 検索する
    }
  }
  .search-button
  , .reset-button {
    height: 5rem;
    line-height: 5rem;
    position: absolute;
  }
  .reset-button {
    width: 16rem;
    padding: 0 0 0 2em;
    background: {
      image: url('../images/icon-reset.svg');
      repeat: no-repeat;
      size: 1.5rem 1.5rem;
      position: calc(50% - 3.5em) 1.75rem; // value: 条件をクリア
    }
  }
  .search-button {
    padding: 0 0 0 1.5em;
    background: {
      image: url('../images/icon-search.svg');
      repeat: no-repeat;
      size: 1.5rem 1.5rem;
      position: calc(50% - 2.5em) 1.75rem; // value: 検索する
    }
  }
  .search-by-keywords {
    position: relative;
    .search-button {
      right: 0;
    }
  }
}

#page-home {
  #find-experts-first-step {
    .inner {
      margin: 0 auto;
      width: 997px;
    }
    .tab-link {
      font-size: 1.8rem;
    }
    .search-by-categories {
      .tab-content {
        padding: 0;
      }
      .categories {
        width: 100%;
        display: table;
        li {
          padding: 0 0.8rem;
          display: table-cell;
          &:nth-child(2) {
            padding-left: 0;
          }
          &:last-child {
            padding-right: 0;
          }
        }
      }
      label {
        height: 6.5rem;
        line-height: 6.5rem;
        width: 100%;
        margin: 0;
        display: block;
        border: 1px solid black;
      }
      input {
        &:checked  + label {
          background: $accent-color;
          border: 1px solid $accent-color;
          margin: 0;
        }
      }
    }
    .call {
      font-size: 1.8rem;
    }
    .search-actions {
      .search-button {
        width: 23.5rem;
        left: calc(50% - 23.5rem / 2);
      }
    }
  }
}

#page-company {
  #find-experts-first-step {
    margin-bottom: 6.4rem;
    #find-experts-by-categories-and-areas {
      padding:  0 0 7.5rem 0;
    }
    .step-header {
      margin: 2.3rem;
    }
    .search-by-area,
    .search-by-categories-wrapper {
      margin: 2.3rem;
    }
    .search-by-area {
      padding: 3.6rem 0;
      label {
        margin: 0;
      }
      select {
        padding-right: 3rem;
        width: 16rem;
      }
    }
    #company_search_form_commercial_area_id {
      width: 13.2rem;
    }
    .select-wrapper-with-triangle {
      margin: 0 0rem;
      display: inline-block;
      &:not(:first-child) {
        margin-left: 1rem;
      }
    }
    .search-by-categories {
      padding: 2rem;
    }
    .categories {
      text-align: left;
      border: none;
      li {
        display: inline-block;
        position: relative;
        line-height: 1;
        margin-bottom: 1.4rem;
        &:not(:nth-child(4n+2)) {
          margin-left: 1.4rem;
        }
      }
      label {
        border: none;
        padding: 0;
        cursor: pointer;
        display: inline-block;
        @include size(15.4rem, 15.4rem);
        border: 0.3rem solid transparent;
        img {
          @include size(100%, 100%);
          pointer-events: none;
        }
      }
      .category-header {
        position: absolute;;
        bottom: 1rem;
        left: 1rem;
        color: white;
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 1.5;
      }
    }
    input[type='checkbox'],
    input[type='radio'] {
      display: none;
      &:checked  + label {
        border: 0.3rem solid $accent-color;
      }
    }
    .call {
      font-size: 1.4rem;
    }
    .search-actions {
      margin-bottom: 0;
      margin: 4.8rem 2.3rem 0;
    }
    .search-actions {
      .search-button {
        width: 20rem;
        left: calc(50% - 20rem / 2);
      }
    }
  }
  #find-experts-by-keywords {
    padding: 0 0 4.3rem 0;
    .search-by-keywords {
      margin: 4.5rem 2.3rem 4.3rem;
    }
  }
  #find-experts-second-step {
    .search-by-area {
      border-bottom: 1px dotted $main-color-clear;
      padding: 2.6rem;
    }
    .search-by-categories {
      width: 100%;
      border-bottom: 1px dotted $main-color-clear;
      padding: 2.1rem;
      li {
        padding: 0.5rem;
      }
      label {
        display: block;
        margin: 0;
      }
    }
    .search-actions {
      margin: 2rem;
    }
  }
}

#page-company #find-experts-second-step,
#page-open-house-and-events-search
{
  .search-by-categories {
    text-align: center;
    label {
      padding: 0.5rem;
    }
    ul {
      display: table;
      width: 100%;
    }
    li {
      padding: 0 0.5rem;
      display: table-cell;
      label {
        padding: 0.5rem;
      }
    }
  }
}

#page-open-house-and-events-search,
#signup-area-select,
{
  .search-form {
    padding: 2.6rem 2.6rem 0.6rem 2.6rem;;
  }
  *[class^='search-by-'] {
    border-bottom: 1px dotted $border-color-demilight;
  }
  .search-by-date {
    display: flex;
    padding: 2rem 0;
  }
  .search-button {
    width: 18.1rem;
  }
  .hasDatepicker {
    width: 20rem;
  }
  .form-header {
    width: 7em;
    white-space: nowrap;
    line-height: 3rem;
    padding: 0;
    text-align: left;
  }
  .search-by-categories {
    display: flex;
    padding: 2rem 0;
    ul {
      padding: 0;
    }
    li {
      padding: 0;
      &:not(:last-child) {
        padding-right: 1rem;
      }
    }
    label {
      margin: 0;
      padding: 0.5rem 1.5rem;
    }
    input:checked + label {
      margin: -0.2rem;
    }
  }
}

#company_search_form_on_top {
  background: #F8F7F0;
  width: 100%;
  .inner {
    max-width: $max-width;
    margin: 0 auto;
    padding: 1.8rem 0;
    display: flex;
  }
  .search-by-keywords {
    display: flex;
    flex-grow: 1;
    line-height: 3.5rem;
    font-size: 1.5rem;
  }
  #company_search_form_keyword {
    flex-grow: 1;
    height: 3.5rem;
    margin-left: 2.7rem;
    border-width: 0 0 0 0.1rem;
    border-color: #979797;
    font-size: 1.5rem;
  }
  .search-button {
    width: 12.9rem;
    height: 3.5rem;
    line-height: 3.5rem;
    margin-right: 1rem;
    padding: 0 0 0 1.5em;
    background: {
      image: url('../images/icon-search.svg');
      repeat: no-repeat;
      size: 1.5rem 1.5rem;
      position: calc(50% - 1.5em) 1rem; // value: 検索
    }
  }
}
