/* companies
----------------------------------------------*/

#page-company {
  #page-header {
    h1::before {
      left: 17rem;
    }
  }
  #news-and-open-house
  , #products-list
  , #past-works-list
  , #message-and-history
  {
    width: $max-width;
    margin: 0 auto;
    padding: 2rem 0 2.4rem 0;
    border-top: 1px solid $border-color-demilight;
    @include clearfix;
    h1 {
      padding: 1rem 0;
      margin-bottom: 4rem;
      font-size: 1.5rem;
      font-weight: 400;
      letter-spacing: 0.2rem;
    }
  }
  #products-list .thumbnail,
  #past-works-list {
    @extend .cropped-photo-large;
  }
}

#company-header {
  padding-top: $cover-height;
  height: $cover-height;
  background: {
    //size: 100% auto;
    size: cover;
    position: center center;
    repeat: no-repeat;
  }
  @include clearfix;
  h1 {
    margin-left: calc($profile-image-size / 2);
    padding: 2rem 2rem 2rem calc($profile-image-size / 2) + 40px;
    font-size: 2.4rem;
  }
  nav {
    width: $max-width;
    position: relative;
    margin: 0 auto;
  }
  #user-actions {
    @extend .user-actions-base;
    text-align: right;
    position: absolute;
    top: -3rem;
    right: 0;
    a, button {
      height: 6rem;
      line-height: 6rem;
    }
  }
  .icon {
    float: left;
    position: absolute;
    bottom: 0;
    @include size($profile-image-size, $profile-image-size);
  }
}

#company-menu {
  background: $base-color;
  border-top: 1px solid $border-color-strong;
  padding: 2rem 2rem 2rem calc($profile-image-size / 2) + 40px;
  margin-left: calc($profile-image-size / 2);
  width: $max-width - calc($profile-image-size / 2);
  ul {
    padding: 0 ;
    text-align: left;
  }
  li {
    display: inline-block;
    font-size: 1.5rem;
    margin-right: 3rem;
  }
  i {
    font-size: 1.8rem;
    margin-right: 1rem;
  }
}

#company-information {
  width: $max-width;
  margin: 0 auto;
  padding: 20rem 0 6rem 0;
  @include clearfix;
}

#about-services {
  float: left;
  font-size: 1.4rem;
  width: calc(60% - 4rem);
  margin-right: 4rem;
  tr {
    border-bottom: 1px solid $border-color-light;
  }
  th, td {
    padding: 2rem;
    margin: 0 ;
  }
  th {
    white-space: nowrap;
  }
  li {
    background: #ccc;
    display: inline-block;
  }
}

#actions-to-company {
  float:right;
  font-size: 1.4rem;
  width: 40%;
  padding-left: 4rem;
  border-left: 1px solid $border-color-light;
  ul {
    text-align: center;
    padding: 2rem 0;
    border-bottom: 1px solid $border-color-light;
  }
  li {
    display: inline-block;
  }
  a {
    display: block;
    padding: 2rem;
  }
  .tel-and-web {
    font-size: 1.6rem;
    font-weight: 700;
  }
  .sns {
    i {
      font-size: 2rem;
    }
  }
  dd {
    margin-bottom: 1em;
  }
}

#news-and-open-house {
  clear: both;
  .slick-slide {
    padding: 4.8rem;
  }
}

#message {
  padding: 5.4rem;
  p {
    margin: 0;
    line-height: 3.2rem;
    font-size: 1.4rem;
  }
}

#history-and-awards {
  display: flex;
  & > *:not(:last-child) {
    border-right: 1px solid $border-color-demilight;
  }
}

#history,
#awards {
  flex-basis: 50%;
  flex-grow: 1;
  padding: 0 5.4rem;
  * {
    font-weight: 400;
    text-align: left;
    line-height: 3.2rem;
    font-size: 1.4rem;
  }
  caption {
    text-align: left;
  }
  th {
    padding-right: 2em;
    white-space: nowrap;
  }
  th, td {
    vertical-align: top;
  }
  td {
  }
}

#preview-notification {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: rgba(100, 100, 100, 0.9);
  color: white;
  z-index: 9999;
  padding: 15px;
  font-size: 1.6rem;
  text-align: center;
}

#more-past-works-button,
#more-news-and-open-houses-button {
  text-align: center;
  padding: 5rem 0;
  font-size: 1.6rem;
  font-weight: 500;
}

#more-products-header {
  font-size: 1.6rem;
  font-weight: 500;
  text-align: center;
  display: block;
  margin: 1rem 2rem;
  line-height: 1;
  img {
    @include size(auto, 1.7rem);
    vertical-align: top;
    margin: 0 1rem 0 0;
  }
}

#more-products-button {
  display: none;
  & + label {
    & + #more-products {
      display: none;
    }
  }
}

#page-open-house-and-events-details {
  #other-events {
    .more {
      padding: 0 0 3rem 0;
      border-bottom: 1px solid $border-color;
    }
    #more-news-and-open-houses {
      & > ul {
        border-top: 1px solid $border-color-demilight;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        text-align: left;
        & > li {
          flex-basis: 50%;
          border-bottom: 1px solid $border-color-demilight;
          padding-top: 3rem;
          &:nth-child(odd) {
            border-right: 1px solid $border-color-demilight;
            padding-right: 3rem;
          }
          &:nth-child(even) {
            padding-left: 3rem;
          }
        }
        & > li:last-child,
        & > li:nth-child(odd):nth-last-child(-n+2) {
          border-bottom: none;
        }
      }
    }
  }
}
