/* past-works-list, products-list
----------------------------------------------*/

#products-list
, #past-works-list {
  ul {
    display: flex;
    flex-wrap: wrap;
  }
  li {
    background: $base-color;
    display: inline-block;
    margin: 2.4rem;
    position: relative;
    flex-basis: 30rem;
    & > a, & > label {
      display: block;
      box-sizing: content-box;
      height: 100%;
      &:hover, &:active {
        border: 3px solid $accent-color;
        margin: -3px;
      }
    }
  }
  h2 {
    font-size: 1.4rem;
    font-weight: 400;
    height: 6rem;
    margin: 0;
  }
  .number {
    font-size: 1.2rem;
    padding: 0 1.4rem 1.4rem;
  }
  .company {
    font-size: 1.3rem;
    text-align: right;
    color: #707070;
    display: block;
    padding: 0 1.4rem 1.4rem;
  }
  .new {
    position: absolute;
    top: 0;
    left: 0;
  }
}

#past-works-list {
  li {
    h2 {
      width: calc(100% - 2.5rem);
    }
  }
}

#page-past-works
, #page-company {
  #products-list
  , #past-works-list {
    li {
      //width: 300px;
    }
  }
}

#page-company {
  #products-list
  , #past-works-list {
    padding: 0 2.6rem;
    li {
      margin: 2.4rem;
    }
    .favorite {
      top: 20rem;
      right: 1rem;
    }
  }
}

#page-past-works {
  #products-list
  , #past-works-list {
    width: 94.5rem;
    li {
      margin: 1.1rem 0;
      .favorite {
        font-size: 2.2rem;
        top: 21rem;
        right: 1rem;
      }
    }
    li:not(:nth-child(3n)) {
      margin-right: 2.2rem;
    }
  }
}

#page-past-works-search
, #page-favorite-past-works {
  #prodcuts-list
  , #past-works-list {
    li {
      margin: 1.75rem 0;
      flex-basis: calc((100% - 3.5rem * 2) / 3);
      &:not(:nth-child(3n)) {
        margin-right: 3.5rem;
      }
      .favorite {
        font-size: 2.2rem;
        top: 16.5rem;
        right: 1rem;
      }
    }
  }
}

#past-works-list {
  h2 {
    padding: 1em;
  }
}

#products-list {
  .modal {
    .modal-fade-screen {
      padding-top: 5em;
    }
    .modal-inner {
      width: 70%;
      font-size: 1.4rem;
    }
  }
  .max-size-photo {
    text-align: center;
    margin-bottom: 1rem;
    img {
      @include size(64rem, 48rem);
      object-fit: contain;
      font-family: 'object-fit: contain';
    }
  }
  h2 {
    padding: 0.9rem;
  }
  .caption {
    width: 64rem;
    margin: 0 auto;
    margin-bottom: 1rem;
  }
  .close {
    text-align: center;
    button {
      font-size: 1.4rem;
      font-weight: 500;
      border: none;
      background: #E8E8E8;
      padding: 0 2rem;
      height: 4.5rem;
      line-height: 4.5rem;
      &:hover, &:active {
        background: tint(#E8E8E8, 20%);
        color: $main-color-clear;
      }
    }
  }
}
