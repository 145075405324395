/* site-header
----------------------------------------------*/

#site-header {
  background: white;
  width: 100%;
  font-size: 1.5rem;
  a:link, a:visited {
    color: $main-color-clear;
  }
  a:hover, a:active {
    color: tint($main-color-clear, 30%);
  }
  #site-header-inner {
    display: table;
    width: 100%;
    max-width: $max-width;
    margin: 0 auto;
    padding-top: 15px;
    padding-bottom: 22px;
  }
  .lead,
  .user-menu {
    display: table-cell;
    vertical-align: middle;
  }
  #global-menu {
    background: $base-color;
    display: block;
    width: 100%;
    ul {
      display: block;
      max-width: 94.5rem + 4rem;
      padding: 0 2rem;;
      margin: 0 auto;
    }
    li {
      display: inline-block;
      letter-spacing: 0.1rem;
      &:not(:last-child) {
        a {
          padding-right: 6.2rem;
        }
      }
    }
    a {
      display: block;
      height: 5rem;
      line-height: 5rem;
    }
  }
  .global-menu-inner {
    max-width: $max-width;
    margin: 0 auto;
    position: relative;
  }
  input[type="search"] {
    min-width: 263px;
  }
}

.open-search-box,
.open-search-box label {
  cursor: pointer;
}
